import * as React from 'react'

const CouponLoading: React.FC = () => {
  const styleSkeleton = 'rounded-lg bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div className="animate-pulse flex items-center bg-coupon bg-no-repeat bg-[length:100%_100%] px-[12px] mb-[2px] h-[156px]">
      <div className="flex flex-row px-[12px] pb-[8px] pt-[2px] rounded-xl h-[126px] w-screen">
        <div className="h-full">
          <p className={`${styleSkeleton} w-28 h-28`} />
        </div>
        <div className="flex flex-col w-full ml-2">
          <div className="h-full w-full">
            <p className={`${styleSkeleton} w-full h-4 mb-2`} />
            <p className={`${styleSkeleton} w-full h-3 mb-2`} />
          </div>
          <div className="bottom-0">
            <p className={`${styleSkeleton} w-8/12 h-3 mb-2 w-[156px]`} />

            <div className="flex flex-row">
              <p className={`${styleSkeleton} w-[49.3px] h-[18px] mr-1`} />
              <p className={`${styleSkeleton} w-[49.3px] h-[18px] mr-1`} />
              <p className={`${styleSkeleton} w-[49.3px] h-[18px] mr-1`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CouponLoading
