import { addGAEvent } from '../../utils/addGoogleAnalytic'
import { COUPONS_LOTUS_TYPE } from '../../utils/constant'
import classNames from 'classnames'
import * as React from 'react'
import { Fragment } from 'react'

interface Props {
  onClick: Function
  active: string
  loading: boolean
}

const subCouponTypeList = [
  { title: 'คูปองหน้าร้าน', trackId: COUPONS_LOTUS_TYPE.OFFLINE },
  { title: 'คูปองออนไลน์', trackId: COUPONS_LOTUS_TYPE.ONLINE },
  { title: 'พิเศษเฉพาะคุณ', trackId: COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU },
]

const FilterCouponLotusTypeLoading = () => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div id="filter-coupon-lotus-type-loading" className="animate-pulse flex flex-row mt-[24px] mb-[16px] overflow-x-auto no-scrollbar">
      <div className="flex flex-col items-center w-[98px] ml-[12px]">
        <p className={`${styleSkeleton} rounded-[20px] w-[98px] h-[32px]`} />
      </div>
      <div className="flex flex-col items-center w-[98px] ml-[8px]">
        <p className={`${styleSkeleton} rounded-[20px] w-[98px] h-[32px]`} />
      </div>
      <div className="flex flex-col items-center w-[98px] ml-[8px]">
        <p className={`${styleSkeleton} rounded-[20px] w-[98px] h-[32px]`} />
      </div>
    </div>
  )
}

const FilterCouponLotusType: React.FC<Props> = ({ onClick, active, loading }) => {
  if (loading) {
    return <FilterCouponLotusTypeLoading />
  }
  return (
    <Fragment>
      {subCouponTypeList.map(({ title, trackId }, index) => {
        return (
          <div
            id={trackId}
            key={index}
            className={classNames('', {
              '!ml-[12px]': index === 0,
              '!mr-[12px]': index === 2,
              'ml-[8px]': index > 0 || index < 2,
            })}
            onClick={() => {
              onClick(trackId)
              localStorage.setItem('refreshCoupons', 'true')
              addGAEvent({
                event: 'mycoupon_category',
                category: title
              })
            }}
          >
            <p
              id={`${trackId}-title`}
              key={index}
              className={classNames(
                'rounded-[8px] py-[6px] px-[12px] border-[#E0E0E0] border-1 whitespace-nowrap text-[16px] font-[700]',
                {
                  'text-[#00BCB4] border-[#00BCB4] bg-[#CEF3F1]': active === trackId,
                  'text-gray-secondary border-[#E0E0E0]': active !== trackId,
                }
              )}
            >
              {title}
            </p>
          </div>
        )
      })}
    </Fragment>
  )
}

export default FilterCouponLotusType
