import { getDateUnix } from '../utils/date'
import { Coupon, CouponsBzbDetails } from '../services/my-coupon-bff/types'
import { OFFERS_BZB_TYPE } from '../utils/constant'
import { getLocalStorageString } from '../utils/storage'

export const REQUEST_TOKEN_MESSAGE = 'request-bzb-token'
const TOKEN_ERROR_MESSAGE = 'bzb-token-error'
export const TOKEN_RECEIVED_MESSAGE = 'bzb-token-received'

export const transformToCoupon = (response: CouponsBzbDetails, offerType: OFFERS_BZB_TYPE): Coupon => {
  const {
    Name,
    StartDate: startDate,
    ExpireDate: expireDate,
    FullImageUrl: url,
    Detail: description,
    Condition: condition,
    Serial: serial,
    RedeemDate: redeemDate,
    IsExpired: isExpired,
    HasWinner: hasWinner,
    IsWinner: isWinner,
    PrivilegeMessage: privilegeMessage,
    ExpireIn: expireIn,
    RedeemKey: id,
  } = response as CouponsBzbDetails

  const formattedExpiryDate = getDateUnix(expireDate, 'BZB')
  const formattedStartDate = getDateUnix(startDate, 'BZB')
  const formattedRedeemDate = getDateUnix(redeemDate, 'BZB')
  
  const transformedUrl = url.replace('?', '-large?')

  return {
    couponId: id,
    couponType: offerType,
    couponName: Name,
    couponCode: serial,
    startDate: formattedStartDate,
    expiryDate: formattedExpiryDate,
    media: { url: transformedUrl },
    description,
    condition,
    acquireDate: formattedRedeemDate,
    isExpired,
    hasWinner,
    isWinner,
    privilegeMessage,
    expireIn,
  } as Coupon
}

export const getBZBToken = (): string => {
  const token = getLocalStorageString('bzb_user_token')
  
  if(token) return token.replace(/"/g, '')
  return ''
}


export const requestBZBToken = (postMessageCounter: number, setPostMessageCounter: Function) => {
  if (postMessageCounter < 5) {
    setPostMessageCounter(postMessageCounter + 1)
    console.log(`Requested BZB token ${postMessageCounter} time(s)`)

    window.parent.postMessage(REQUEST_TOKEN_MESSAGE, '*')
  } else {
    window.parent.postMessage(TOKEN_ERROR_MESSAGE, '*')
    console.error('Failed to receive BZB token after maximum retries')
  }
}

export const getOfferType = (type: number, time: number) => {
  if (type === 1) {
    return time ? OFFERS_BZB_TYPE.COUNTDOWN : OFFERS_BZB_TYPE.NON_COUNTDOWN
  } else if (type === 20) {
    return OFFERS_BZB_TYPE.CHARITY
  } else if (type === 0) {
    return OFFERS_BZB_TYPE.LUCKY_DRAW
  } else if (type === 8) {
    return OFFERS_BZB_TYPE.WEB_VIEW
  } else {
    return OFFERS_BZB_TYPE.NON_COUNTDOWN
  }
}