import classNames from 'classnames'
import * as React from 'react'

export interface Props {
  title: string
  onClick: Function
  disabled: boolean
}

const Button: React.FC<Props> = ({ title, onClick, disabled }) => {
  return (
    <button
      id="offer-details-footer-button"
      type="button"
      disabled={disabled}
      className={classNames(
        'text-white w-full leading-7 text-lg font-lotuss font-bold py-2 rounded-lg bg-blue-iris disabled:opacity-50'
      )}
      onClick={() => onClick()}
    >
      {title}
    </button>
  )
}

export default Button