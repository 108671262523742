import * as React from 'react'

export interface Props {
  isLoading: boolean
  onClick: Function
}

const SearchOfferSpecialStore: React.FC<Props> = ({ isLoading, onClick }) => {
  const handleOnClickSearch = () => {
    if(window) window.parent.postMessage('search-offer-special', '*')
  }

  return (
    <div
      onClick={() => {
		handleOnClickSearch()
        onClick()
      }}
      className="flex flex-row mx-3 my-4 border-1 rounded-lg border-gray-[#6B6B6B] text-base bg-gray-disable py-[6px] px-[8px]"
    >
      <img className="w-[17px]" src="/icons/search-icon.svg" alt="icon" />
      <span
        className={`ml-[8px] text-center content-center text-base leading-6 text-gray-inactive ${
          isLoading ? 'hidden' : ''
        }`}
      >
        ค้นหาสิทธิพิเศษ
      </span>
    </div>
  )
}

export default SearchOfferSpecialStore
