import { Coupon, OfferDetails, Profile } from '../../../services/my-coupon-bff/types'
import { Alert, CardDetail, CardDetailLoading, Footer, Loading } from '../../../common'
import { getLotusCouponsByType, getProfile, redeemingOffer } from '../../../services/my-coupon-bff'
import { useEffect, useState } from 'react'
import { getStatus } from '../../../utils/status'
import { ERROR_TYPE, matchErrorMessgeRedeemOffer } from '../../../utils/error'
import { ModalConfirmRedeem, ModalError } from './Modal'
import { setUrl } from '../../../utils/params'
import { OFFERS_TYPE } from '../../../utils/constant'
import classNames from 'classnames'
import { getLocalStorageString, setLocalStorage } from '../../../utils/storage'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'

export type Props = {
  loading: boolean
  name: string
  offer: OfferDetails | null
  setLoading: (loading: boolean) => void
  profile: Profile | undefined
}

const OfferDetail: React.FC<Props> = ({ loading, name, offer, setLoading, profile }) => {
  const token = localStorage?.getItem('token')
  const expiryDate = offer?.offerDetails.acquisitionEndDate || 'N/A'
  const price = offer?.price || null
  const status = getStatus(expiryDate, profile?.points, price)
  const internalName = offer?.offerDetails.internalName

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isLoadingIcon, setIsLoadingIcon] = useState(false)
  const [error, setError] = useState<string>()
  const [isInternetError, setIsInternetError] = useState<boolean>(false)
  const [localProfile, setLocalProfile] = useState<Profile>()

  useEffect(() => {
    setLocalProfile(profile)
  }, [profile])

  async function onRefreshProfile() {
    try {
      const response = await getProfile()

      if (response) {
        const result = response as Profile
        setLocalProfile(result)
      }
    } catch (e) { }
  }

  async function redeemOffers() {
    if (!offer) return
    setIsButtonDisabled(true)
    setIsLoadingIcon(true)

    try {
      const response = await redeemingOffer(offer.id) as Coupon
      if (response) {
        let result = undefined
        const responseCouponByType = await getLotusCouponsByType(response.couponType) as Coupon[]
        if (responseCouponByType.length > 0) {
          result = responseCouponByType.find((data) => response.couponId === data.couponId)
        }

        setIsShowModal(false)
        setIsLoadingIcon(false)
        setLocalStorage('successMessage', 'แลกคอยน์สำเร็จ')
        setLocalStorage('couponDetail', result || {})

        if (window) {
          window.parent.postMessage('back-to-coupons-list', '*')
          window.history.replaceState('detail', '', '/coupons' + window.location.search)
          window.location.reload()
        }
      }
    } catch (e: any) {
      setIsLoadingIcon(false)

      if (e?.error) {
        const error = matchErrorMessgeRedeemOffer(e?.code)

        if (error === ERROR_TYPE.INSUFFICIENT_POINT) {
          if (token) {
            setLoading(false)
            onRefreshProfile()
            setError(error)
          }
        }
        if (error === ERROR_TYPE.DEFAULT) {
          setError(error)
          setTimeout(() => setError(undefined), 3000)
        }
        setError(error)
        setIsShowModal(false)
        setIsButtonDisabled(false)
      }
    }
  }

  useEffect(() => {
    if (status || offer?.availableInventoryRemaining === 0) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
  }, [status, offer])

  return (
    <>
      <Loading idElement="offer-details-loading" isShow={isLoadingIcon} />
      <div
        id="offer-details-alert-no-internet-container"
        className={classNames('fixed flex flex-row h-full bottom-[-100%] mb-[136px] z-[100]', {
          hidden: !isInternetError,
        })}
      >
        <Alert idElement="offer-details-no-internet" description={'ไม่มีการเชื่อมต่ออินเทอร์เน็ต'} icon="information-circle" />
      </div>
      <ModalConfirmRedeem
        idElement="offer-details-modal-confirm-redeem"
        offerType={offer?.offerDetails.offerType as OFFERS_TYPE}
        disabled={isButtonDisabled}
        coin={offer?.price}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onClick={() => {
          addGAEvent({
            event: 'lineliff_lotuss_offer_confirm_redeem',
            offer_name: offer?.offerDetails.internalName,
          })
          redeemOffers()
        }}
        offerName={offer?.offerDetails.internalName}
      />
      <ModalError
        idElement="offer-details-modal-error-insufficient-offer"
        isShowModal={error === ERROR_TYPE.INSUFFICIENT_OFFER}
        setIsShowModal={() => {
          const goToOffersPage = `/offers?type=${OFFERS_TYPE.PREMIUM}`

          setUrl(goToOffersPage)
          setError(undefined)
          setIsShowModal(false)
        }}
        title='ขออภัย<br />มีผู้ใช้สิทธิ์ครบตามจำนวนแล้ว'
        description='กรุณาดูสิทธิพิเศษอื่นๆ'
      />
      <ModalError
        idElement="offer-details-modal-error-insufficient-point"
        isShowModal={error === ERROR_TYPE.INSUFFICIENT_POINT}
        setIsShowModal={() => {
          setError(undefined)
          setIsShowModal(false)
        }}
        title='โลตัสคอยน์ของคุณไม่เพียงพอ'
      />
      <ModalError
        idElement="offer-details-modal-error-redemption-limit"
        isShowModal={error === ERROR_TYPE.REDEMPTION_LIMIT}
        setIsShowModal={() => {
          const goToOffersPage = `/offers?type=${OFFERS_TYPE.PREMIUM}&token=${token}`

          setError(undefined)
          setIsShowModal(false)
          setUrl(goToOffersPage)
        }}
        title={ERROR_TYPE.REDEMPTION_LIMIT}
      />
      <ModalError
        idElement="offer-details-modal-error-global-frequency"
        isShowModal={error === ERROR_TYPE.GLOBAL_FREQUENCY}
        setIsShowModal={() => {
          const goToOffersPage = `/offers?type=${OFFERS_TYPE.PREMIUM}&token=${token}`

          setError(undefined)
          setIsShowModal(false)
          setUrl(goToOffersPage)
        }}
        title={ERROR_TYPE.GLOBAL_FREQUENCY}
        description='โปรดกลับมารับสิทธิ์ในครั้งถัดไป หรือ <br/> ดูสิทธิพิเศษอื่นๆ'
      />
      <div id="offer-details-content" className='flex flex-col h-full'>
        <div id="scrollable-offer-detail" className='flex-1 overflow-y-auto overscroll-y-contain'>
          {
            loading ?
              <CardDetailLoading idElement="offer-details-card-loading" />
              :
              (
                offer ?
                  <CardDetail
                    idElement="offer-details-card-detail"
                    image={offer.offerDetails.media.url}
                    name={offer.offerDetails.title}
                    startDate={offer.offerDetails.acquisitionStartDate}
                    expiryDate={expiryDate}
                    description={offer.offerDetails.description}
                    condition={offer.offerDetails.condition}
                    showFooterRedeem={true}
                    displayName={name}
                    point={profile?.points}
                    offerPrice={price}
                  >
                    <div id="offer-details-card-detail-point-inventory-remaining" className='flex justify-center items-center p-3 rounded-lg bg-[#EDFCFC]'>
                      <div id="offer-details-card-detail-point-content" className='flex flex-col items-center flex-1'>
                        <p id="offer-details-card-detail-point-used" className='leading-6 text-base font-lotuss text-gray-secondary'>จำนวนคอยน์ที่ใช้</p>
                        <p id="offer-details-card-detail-point" className='leading-7 text-lg font-bold font-lotuss text-gray-primary'>
                          {`${offer.price === 0 ? 'ฟรี' : `${offer.price} คอยน์` }`}
                        </p>
                      </div>
                      <div id="offer-details-card-detail-inventory-remaining-content" className={classNames(
                        'flex flex-col items-center border-l-1 flex-1',
                        { 'hidden': !offer?.availableInventoryRemaining && offer?.availableInventoryRemaining !== 0 }
                      )} >
                        <p id="offer-details-card-detail-number-permissions" className='leading-6 text-base font-lotuss text-gray-secondary'>จำนวนสิทธิ์</p>
                        <p id="offer-details-card-detail-inventory-remaining" className='leading-7 text-lg font-bold font-lotuss text-gray-primary'>
                          {offer?.availableInventoryRemaining}
                        </p>
                      </div>
                    </div>
                  </CardDetail>
                  :
                  ''
              )
          }
        </div>
        <div id="offer-details-footer" className={classNames('flex-0', {
          hidden: getLocalStorageString('special-store-id') || getLocalStorageString('special-store-id-from-param'),
        })}>
          <Footer
            error={error}
            loading={loading}
            displayName={localProfile?.name || name}
            cardName='มายโลตัส'
            coin={localProfile?.points}
          >
            <button
              id="offer-details-footer-button"
              type='button'
              disabled={isButtonDisabled}
              className='text-white w-full leading-7 text-lg font-lotuss font-bold p-2 my-3 rounded-lg bg-blue-iris disabled:opacity-50'
              onClick={() => {
                addGAEvent({
                  event: 'view_lineliff_lotuss_offer_details',
                  offer_name: internalName,
                })
                if (!navigator.onLine) {
                  setIsInternetError(true)
                  setTimeout(() => setIsInternetError(false), 3000)
                } else {
                  setIsShowModal(!isShowModal)
                }
              }}
            >
              รับสิทธิ์
            </button>
          </Footer>
        </div>
      </div>
    </>
  )
}

export default OfferDetail