import React from 'react'

const EmptySpecialStoreList: React.FC = () => {
  return (
    <div data-testid="empty-offers-special-store-page" id="empty-offers-special-store-page" className="m-auto">
      <div className="flex flex-col mb-[48px]">
        <img className="w-40" src="/images/empty-rewards.svg" alt="loading-icon" />
        <p className="font-medium text-base text-gray-empty text-center">ติดตามสิทธิพิเศษได้เร็วๆนี้</p>
      </div>
    </div>
  )
}

export default EmptySpecialStoreList
