export const LOTUS = `Lotus’s`

export enum OFFERS_TYPE {
  PREMIUM = 'Premium',
  VOUCHER = 'Voucher',
  SPECIAL_FOR_YOU = 'SpecialForYou',
  ALL = '',
}

export enum OFFERS_BZB_TYPE {
  NON_COUNTDOWN = 'Non-Countdown',
  COUNTDOWN = 'Countdown',
  CHARITY = 'Charity',
  LUCKY_DRAW = 'Lucky draw',
  WEB_VIEW = 'Web view',
}

export const OFFERS_SPECIAL_LOTUS_TYPE = {
  PREMIUM: 'Premium',
  VOUCHER: 'Voucher',
  SPECIAL_FOR_YOU: 'SpecialForYou',
  ALL: '',
}

export enum COUPONS_TYPE {
  PREMIUM = 'Premium',
  POWER_UP = 'PowerUp',
  ONLINE = 'Online',
  VOUCHER = 'Voucher',
  SPECIAL_FOR_YOU = 'SpecialForYou',
}

export const COUPONS_LOTUS_TYPE = {
  OFFLINE: 'Offline',
  ONLINE: 'Online',
  SPECIAL_FOR_YOU: 'SpecialForYou',
}

export enum COUPON_CATEGORY {
  VOUCHER = 'voucher',
  CATEGORY = 'category',
  PRODUCT = 'product',
}

export enum COUPON_MODE {
  BARCODE = 'BARCODE',
  QRCODE = 'QRCODE',
}

export enum CHANNEL_TYPE {
  ONLINE = 'online',
  OFFLINE = 'offline',
  ONLINE_TH = 'ออนไลน์',
  OFFLINE_TH = 'หน้าร้าน',
}

export const DELIVERY_TYPE = {
  IMMEDIATELY: ['HD', 'HDSD'],
  NEXT_DAY: ['HDND'],
  IN_STORE: ['CC', 'CCSD', 'CCND'],
  IMMEDIATELY_TH: 'ส่งทันที',
  NEXT_DAY_TH: 'ส่งวันถัดไป',
  IN_STORE_TH: 'รับที่ร้าน',
}

export enum KEY_STORAGE {
  COUPONS_EXCHANGED_LIST = 'coupons-exchanged-list',
}
