export const getParam = (filed: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  const value = urlParams.get(filed) || ''

  return value
}

export const setUrl = (path: string) => {
  window.location.href = path
}
