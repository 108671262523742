export enum ERROR_TYPE {
  INVENTORY_FAIL = 'ขออภัย สิทธิ์ในการแลกหมด',
  DEFAULT = 'มีบางอย่างผิดพลาด กรุณาลองอีกครั้ง',
  INSUFFICIENT_POINT = 'คอยน์ไม่เพียงพอ',
  REDEMPTION_LIMIT = 'ขออภัย คุณใช้สิทธิ์ครบตาม กำหนดแล้ว',
  INSUFFICIENT_OFFER = 'ขออภัย ไม่สามารถทำรายการได้',
  GLOBAL_FREQUENCY = 'ขออภัย คุณใช้สิทธิ์ครบตามกำหนดแล้ว',
}

export enum ERROR_BZB_CODE {
  OUT_OF_STOCK = 1,
  LIMIT_EXCEED = 2,
  NOT_ENOUGH_COIN = 1302,
  EXPIRED_OFFER = 1409,
  CODE_3 = 3,
  CODE_1403 = 1403,
  CODE_1406 = 1406,
  CODE_1410 = 1410,
  CODE_1414 = 1414,
  CODE_1416 = 1416,
  CODE_1421 = 1421,
}
export const ERROR_BZB_NORMAL = [
  ERROR_BZB_CODE.OUT_OF_STOCK, 
  ERROR_BZB_CODE.LIMIT_EXCEED, 
  ERROR_BZB_CODE.EXPIRED_OFFER,
  ERROR_BZB_CODE.CODE_3,
  ERROR_BZB_CODE.CODE_1403,
  ERROR_BZB_CODE.CODE_1406,
  ERROR_BZB_CODE.CODE_1410,
  ERROR_BZB_CODE.CODE_1414,
  ERROR_BZB_CODE.CODE_1416,
  ERROR_BZB_CODE.CODE_1421,
]

export enum ERROR_GENERAL {
  NO_INTERNET = 'No internet',
  DEPENDENCY_ERROR = 'Dependency error',
  TOKEN_EXPIRED = 1905,
}

export const matchErrorMessgeRedeemOffer = (code: number): string => {
  if (code === 40601) {
    return ERROR_TYPE.INSUFFICIENT_OFFER
  }
  if (code === 40602) {
    return ERROR_TYPE.REDEMPTION_LIMIT
  }
  if (code === 40005) {
    return ERROR_TYPE.INSUFFICIENT_POINT
  }
  if (code === 40609 || code === 40610) {
    return ERROR_TYPE.GLOBAL_FREQUENCY
  } 

  return ERROR_TYPE.DEFAULT
}

export const checkNetworkError = (): boolean => {
  return !navigator.onLine
}
