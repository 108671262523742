import { Modal } from '../../../common'

export enum LUCKY_DRAW_TYPE {
  WINNER = 'WINNER',
  NOT_WINNER = 'NOT_WINNER',
  NOT_ANNOUNCE = 'NOT_ANNOUNCE'
}

enum TITLE {
  WINNER = 'ขอแสดงความยินดี!',
  NOT_WINNER = 'ขอแสดงความเสียใจ',
  NOT_ANNOUNCE = 'ยังไม่ประกาศผลผู้ชนะ',
}

enum DESCRIPTION {
  WINNER = 'คุณคือผู้โชคดี เราจะติดต่อคุณเพื่อรับรางวัล',
  NOT_WINNER = 'คุณไม่ได้รับของรางวัล\nโปรดมาร่วมสนุกกับกิจกรรมในครั้งต่อไป',
  NOT_ANNOUNCE = 'ประกาศผลผู้ชนะกิจกรรมภายใน',
}

enum ICON {
  WINNER = '/icons/winner-icon.png',
  NOT_WINNER = '/icons/not-winner-icon.png',
  NOT_ANNOUNCE = '/icons/not-announce-icon.png',
}

interface IProps {
  idElement: string
  isShowModal: boolean
  onClick: Function
  setIsShowModal: Function
  type: LUCKY_DRAW_TYPE
  privilegeMessage: string | null
}

export const ModalLuckyDraw = ({
  idElement,
  isShowModal,
  setIsShowModal,
  onClick,
  type,
  privilegeMessage
}: IProps) => {
  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <div className='mx-[28px] mb-[20px] mt-[35px]'>
        <img 
          src={ICON[type]} 
          width={64} 
          height={64} 
          alt='success-icon' 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/icons/coupon-image-placeholder.svg'
            currentTarget.className = 'rounded-lg w-[66px]'
          }}
        />
      </div>
      <div id={`${idElement}-content`} className='px-[24px] font-lotuss mb-[18px]'>
        <p id={`${idElement}-text-confirm`} className='text-[24px] leading-9 text-gray-primary font-bold mb-[8px]'>
          {TITLE[type]}
        </p>
        <p id={`${idElement}-text-2`} className='text-gray-empty text-[16px] whitespace-pre-line' data-test='description'>
          {`${DESCRIPTION[type]} ${type === LUCKY_DRAW_TYPE.NOT_ANNOUNCE ? (privilegeMessage || '15 วัน') : ''}`}
        </p>
      </div>
      <div id={`${idElement}-button`} className='flex mb-[24px] px-[12px] font-lotuss'>
        <button
          id={`${idElement}-button-confirm`}
          onClick={onClick ? () => onClick() : undefined}
          type='button'
          className='flex-1 text-white text-lg font-bold p-2 rounded-lg bg-blue-iris disabled:opacity-50'
        >
          ตกลง
        </button>
      </div>
    </Modal>
  )
}