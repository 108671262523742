import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import duration from 'dayjs/plugin/duration'

dayjs.locale('th')
dayjs.extend(buddhistEra)
dayjs.extend(duration)

export const getDate = (date: string, format: string) => {
  return dayjs(date).format(format)
}

export const getDateUnix = (timestamp: number, type: string, format?: string) => {
  // Define a constant for the time offset (e.g., 7 hours in seconds for BZB type)
  const timeOffsetInSeconds = type.includes('BZB') ? 7 * 3600 : 0
  const adjustedTimestamp = timestamp - timeOffsetInSeconds
  const date = dayjs.unix(adjustedTimestamp)

  if (!format) return date.toISOString()
  return date.format(format)
}

export const formatMinutesToHours = (minutes: number | undefined): string => {
  if (minutes === undefined || minutes === 0) return '0 นาที' 

  if (minutes < 60) {
    return `${minutes} นาที`
  }

  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60

  return mins ? `${hours} ชั่วโมง ${mins} นาที` : `${hours} ชั่วโมง`
}

export const DATE_FORMAT = 'DD MMM BBBB'
