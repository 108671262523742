import * as React from 'react'
import Alert from '../alert'
import { getDisplayCoin } from '../../utils/functions'

export interface Props {
  displayName: string
  cardName: string
  coin: number | undefined
  loading: boolean
  children?: string | JSX.Element | JSX.Element[]
  error?: string
  success?: string
  isShowRedeemBtn?: boolean 
}
export interface PropsFooterLoading {
  isShowBtn: boolean
}

const FooterLoading: React.FC<PropsFooterLoading> = ({ isShowBtn }) => {
  return (
    <div id="footer-loading-container" data-testid='footer-loading' role='status' className='flex flex-col fixed inset-x-0 bottom-0 border-t-1 border-gray-300 px-3 py-3 bg-footer bg-white bg-cover'>
      <div id="footer-loading-content" className='flex flex-row items-center py-1.5'>
        <img id="footer-loading-image-lotuss-card" data-testid='footer-image' src='/images/lotuss-card.svg' className='w-[28px]' alt='card' />
        <div id="footer-loading-display-name" className='max-w-sm animate-pulse bg-white-smoke rounded-lg dark:bg-gray-91 w-16 h-4 ml-[10px]' />
        <div id="footer-loading-coin" className='flex flex-row fixed right-2'>
          <img id="footer-loading-image-coin" data-testid='coin-image' src='/icons/coin.svg' className=' right-0 w-[18px] mr-[4px]' alt='coin' />
          <div id="footer-loading-display-coin" className='max-w-sm animate-pulse bg-white-smoke rounded-lg dark:bg-gray-91 w-16 h-4' />
        </div>
      </div>
      {isShowBtn && (
        <div data-testid='card-detail-detail' className='flex flex-col'>
          <button className={`rounded-lg bg-gradient-to-l from-smoke to-white-smoke h-[44px] p-2 my-3 w-full`}/>
        </div>
      )}
    </div>
  )
}

const Footer: React.FC<Props> = ({ displayName, cardName, coin, loading, children, error, success, isShowRedeemBtn }) => {
  const displayCoin = coin
    ? getDisplayCoin(coin)
    : coin === 0
    ? '0.00'
    : 'N/A'

  if (loading) {
    return <FooterLoading isShowBtn={isShowRedeemBtn || false} />
  }

  return (
    <div id="footer-container" className='footer'>
      {error && <Alert idElement="footer-alert-error" description={error} icon='information-circle' />}
      {success && <Alert idElement="footer-alert-success"  description={success} icon='check' />}
      <div id="footer-content" className='flex flex-col border-[#E0E0E0] px-3 py-2 bg-footer border-t-1 bg-cover bottom-0 bg-[#EDFCFC]'>
        <div id="footer-image-display" className='flex flex-row items-center py-1.5 relative'>
          <img id="footer-image-lotuss-card" data-testid='footer-image' src='/images/lotuss-card.svg' className='w-[28px]' alt='card' />
          <p id="footer-display-name" data-testid='footer-display-name' className='text-base leading-6 tracking-wide text-gray-primary px-2.5'>{displayName}</p>
          <div id="footer-coin" className='flex flex-row right-0 absolute'>
            <img id="footer-image-coin" data-testid='coin-image' src='/icons/coin.svg' className='right-0 w-[18px] mr-[4px]' alt='coin' />
            <p id="footer-display-coin" data-testid='footer-display-coin' className='text-2xl font-bold text-gray-primary tracking-wide1 leading-9'>{displayCoin}</p>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Footer
