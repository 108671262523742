import { CampaignCategory } from '../../services/my-coupon-bff/types'
import classNames from 'classnames'
import * as React from 'react'

interface Props {
  list: CampaignCategory[]
  active: number
  isLoading: boolean
  onClick: Function
}

const FilterOfferSpecialStoreLoading = () => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div id="filter-offer-special-store-loading" className='animate-pulse flex flex-row p-2 m-2'>
      <div id="filter-offer-special-store-loading-content0" className='flex flex-col items-center w-[86px]'>
        <p id="filter-offer-special-store-loading-content0-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-special-store-loading-content0-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
      <div id="filter-offer-special-store-loading-content1" className='flex flex-col items-center w-[86px]'>
        <p id="filter-offer-special-store-loading-content1-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-special-store-loading-content1-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
      <div id="filter-offer-special-store-loading-content2" className='flex flex-col items-center w-[86px]'>
        <p id="filter-offer-special-store-loading-content2-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-special-store-loading-content2-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
      <div id="filter-offer-special-store-loading-content3" className='flex flex-col items-center w-[86px]'>
        <p id="filter-offer-special-store-loading-content3-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-special-store-loading-content3-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
      <div id="filter-offer-special-store-loading-content4" className='flex flex-col items-center w-[86px]'>
        <p id="filter-offer-special-store-loading-content4-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-special-store-loading-content4-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
    </div>
  )
}

const FilterOfferSpecialStore: React.FC<Props> = ({ list, active, isLoading, onClick }) => {
  if (isLoading) {
    return <FilterOfferSpecialStoreLoading />
  }

  return (
    <div id="filter-offer-special-store" className={classNames('flex items-baseline mb-[8px]')}>
      {list.map(({ id, mode, name_en, name_th, image_url }, index) => {
         let keyID = id
         let image = image_url
         
         if(mode?.toLocaleLowerCase() === 'all') {
           keyID = 0
           image = '/icons/filter-all-icon.svg'
         }
 
        return <div
          id={`filter-offer-special-store-content${index}`}
          className='flex flex-col items-center w-[84px]'
          key={keyID}
          onClick={() => {
            onClick(keyID)
          }}
        >
          <div className='w-[46px] mx-[18px] '>
            <img
              id={`filter-offer-special-store-image${index}`}
              src={`${image}`}
              alt='icon'
              className={classNames(
                'rounded-lg w-[46px]',
                { 'grayscale-[100%] brightness-[1.20]': active !== keyID }
              )} 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/icons/filter-default-icon.svg'
              }}
            />
          </div>

          <div
            id={`filter-offer-special-store-title${index}`}
            key={index}
            className={classNames(
              'text-center px-[8px] rounded-xl text-xs leading-[18px] tracking-wide',
              {
                'text-blue-iris': active === keyID,
                'text-gray-secondary': active !== keyID,
              }
            )}
          >
            {name_th}
          </div>
        </div>
      })}
    </div>
  )
}

export default FilterOfferSpecialStore