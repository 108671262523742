import React, { useEffect, useState, useRef, useCallback } from 'react'
import { CouponsLotusList, CouponsV2List } from '../../components'
import { getLotusCouponsByType, getProfile } from '../../services/my-coupon-bff'
import { Coupon, Profile } from '../../services/my-coupon-bff/types'
import { COUPONS_LOTUS_TYPE, COUPONS_TYPE, KEY_STORAGE, OFFERS_BZB_TYPE } from '../../utils/constant'
import { getParam } from '../../utils/params'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Transition } from 'react-transition-group'
import { FilterCoupon, GeneralError, FilterCategoryCoupon, Alert } from '../../common'
import './CouponsPage.css'
import { isDeviceRunningiOS } from '../../utils/validator'
import CouponsLotusDetails from '../../components/coupons/couponsLotusDetails'
import CouponsPremiumDetails from '../../components/premium/couponsDetails'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { checkNetworkError } from '../../utils/error'
import { ErrorResponse } from '../../utils/reponseType'
import { CouponLotusListForwardRefObject } from '../../components/coupons/couponsLotusList/CouponsLotusList'

import NetworkErrorLogo from '../../assets/images/network-error.svg'
import BadRequestLogo from '../../assets/images/bad-request.svg'
import { getLocalStorageJson, getLocalStorageString, removeLocalStorage, setLocalStorage } from '../../utils/storage'
import { addGAEvent } from '../../utils/addGoogleAnalytic'
import CouponsSpecialStoreDetails from '../../components/coupons/couponsSpecialStoreDetails'
import { getBZBToken, REQUEST_TOKEN_MESSAGE, requestBZBToken, TOKEN_RECEIVED_MESSAGE } from '../../components/functions'

const Coupons: React.FC = () => {
  const { couponId } = useParams()
  const [profile, setProfile] = useState<Profile>()
  const [loading, setLoading] = useState<boolean>(false)
  const [typeOfPage, setTypeOfPage] = useState<string>()
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | undefined>()
  const [isShowingSelectedCoupon, setIsShowingSelectedCoupon] = useState<boolean>(couponId ? true : false)
  const nodeRef = useRef(null)
  const [durationTransition, setDurationTransition] = useState<number>(500)
  const [indexTrigger, setIndexTrigger] = useState<number>(-1) // this is a workaround to trigger index for back button, it must be enhanced
  const [message, setMessage] = useState<string>('')
  const [isShowingBalloon, setIsShowingBalloon] = useState<boolean>(false)
  const isIos = isDeviceRunningiOS()
  const [tabIndex, setTabIndex] = useState(getLocalStorageJson(KEY_STORAGE.COUPONS_EXCHANGED_LIST) ? 1 : 0)
  const isNetworkError = checkNetworkError()
  const [isInternalError, setIsInternalError] = useState<boolean>(false)
  const [activeCouponType, setActiveCouponType] = useState<string>(COUPONS_LOTUS_TYPE.OFFLINE)
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(true)
  const [couponType, setCouponType] = useState<string>(COUPONS_LOTUS_TYPE.OFFLINE)
  const [type, setType] = useState<OFFERS_BZB_TYPE | undefined>()
  const [isHideCouponMenu, setHideCouponMenu] = useState<boolean>(false)
  const [isHideCategoryMenu, setHideCategoryMenu] = useState<boolean>(true)
  const [activeCategory, setActiveCategory] = useState<number>(0)
  const couponLotusListComponentRef = useRef<CouponLotusListForwardRefObject>(null)
  const [couponDetailId, setCouponDetailId] = useState<string>('')
  const [loadingCouponDetail, setLoadingCouponDetail] = useState<boolean>(couponId ? true : false)
  // event message to line
  const [postMessageCounter, setPostMessageCounter] = useState<number>(0)

  function checkCouponFromRedemption(callback: Function) {
    const couponDetailInLocalStorage: Coupon = getLocalStorageJson('couponDetail')
    if (couponDetailInLocalStorage) {
      removeLocalStorage('couponDetail')
      callback(couponDetailInLocalStorage)
    }
  }

  function showBalloon() {
    setIsShowingBalloon(true)
    setTimeout(() => {
      setIsShowingBalloon(false)
    }, 3000)
  }


  const handleParentEvent = useCallback(
    async (event: MessageEvent) => {
      const bzbToken = getLocalStorageString('bzb_user_token')
  
      if (!bzbToken) window.parent.postMessage(REQUEST_TOKEN_MESSAGE, '*')
      if (typeof event.data === 'string') {
        if (event.data.match(TOKEN_RECEIVED_MESSAGE)) {
          const token = JSON.parse(event.data).token

          if (token) {
            localStorage.setItem('bzb_user_token', token)
          } else {
            requestBZBToken(postMessageCounter, setPostMessageCounter)
          }
        }
      }
    },
    [postMessageCounter]
  )

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleParentEvent)
    }
    return () => {
      window.removeEventListener('message', handleParentEvent)
    }
  }, [handleParentEvent, tabIndex])

  useEffect(() => {
    if (window) {
      const requestIdString = getParam('x-request-id')
  
      if (requestIdString) {
        const requestIdList = requestIdString.split(',')
        window.__IFRAME_DEBUGGER_PARAMS = requestIdList ?? []
        console.log('in coupon', window.__IFRAME_DEBUGGER_PARAMS)
      }
      checkCouponFromRedemption((coupon: Coupon) => {
        if (
          coupon.couponType === COUPONS_TYPE.PREMIUM ||
          coupon.couponType === COUPONS_TYPE.POWER_UP ||
          getLocalStorageString('tab') === 'offer-special-detail'
        ) {
          setTabIndex(1)
          setTypeOfPage(COUPONS_TYPE.PREMIUM || COUPONS_TYPE.POWER_UP)
          removeLocalStorage('tab')
          localStorage.setItem('refreshCoupons', 'true')
        } else {
          setTabIndex(0)
        }
        if([
          OFFERS_BZB_TYPE.CHARITY, 
          OFFERS_BZB_TYPE.COUNTDOWN,
          OFFERS_BZB_TYPE.NON_COUNTDOWN,
        ].includes(coupon.couponType as OFFERS_BZB_TYPE)){
          setType(coupon.couponType as OFFERS_BZB_TYPE)
        }
        setMessage(getLocalStorageJson('successMessage') || '')
        showBalloon()
        setDurationTransition(0)
        setIsShowingSelectedCoupon(true)
        setSelectedCoupon(coupon)
        setIndexTrigger(99999)
        setCouponDetailId(coupon.couponId)
        setIsShowingBalloon(true)
        setTimeout(() => {
          setDurationTransition(500)
        }, 500)
      })
    }
  }, [handleParentEvent])

  async function refreshProfile() {
    fetchProfile()
    localStorage.setItem('refreshCoupons', 'true')
    localStorage.setItem('refreshCouponLotus', 'true')
  }

  async function fetchProfile() {
    try {
      setIsInternalError(false)
      setLoading(true)
      setLoadingCoupon(true)
      const response = await getProfile()

      if (response) {
        setProfile(response as Profile)
        setLoading(false)
        setLoadingCoupon(false)
      }
    } catch (e) {
      const error = e as ErrorResponse

      if (error.code === 500) {
        setIsInternalError(true)
      }
      setLoading(false)
      setLoadingCoupon(false)
    }
  }
  
  const fetchCouponForDetailPage = useCallback(async (couponType: string, couponId: string) => {
    try {

      setIsInternalError(false)
      setLoadingCouponDetail(true)

      setCouponDetailId(couponId)
      setDurationTransition(0)
      setIsShowingSelectedCoupon(true)
      setIndexTrigger(99999)
      setTimeout(() => {
        setDurationTransition(500)
      }, 500)
      let coupon = undefined
      const response = await getLotusCouponsByType(couponType) as Coupon[]
      if (response.length > 0) {
        coupon = response.find((data) => couponId === data.couponId)
      }
      if (coupon) {
        setSelectedCoupon(coupon)
        setLoadingCouponDetail(false)
      }
    } catch (e) {
      const error = e as ErrorResponse
      if (error.code === 500) {
        setIsInternalError(true)
      }
      setLoadingCouponDetail(false)
    }
  }, [
    setDurationTransition,
    setIsShowingSelectedCoupon,
    setIndexTrigger,
    setIsInternalError,
    setLoadingCouponDetail,
    setSelectedCoupon,
    setCouponDetailId
  ])

  useEffect(() => {
    const type = getParam('type')
    if (couponId && !couponDetailId) fetchCouponForDetailPage(type, couponId)
    if (type === COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU) setCouponType(type)
    if (!profile?.name) fetchProfile()

    if (getLocalStorageJson(KEY_STORAGE.COUPONS_EXCHANGED_LIST)) {
      setTypeOfPage(COUPONS_TYPE.PREMIUM || COUPONS_TYPE.POWER_UP)
      localStorage.setItem('refreshCoupons', 'true')
      removeLocalStorage(KEY_STORAGE.COUPONS_EXCHANGED_LIST)
    }
  }, [profile, couponId, couponDetailId, fetchCouponForDetailPage, setCouponType])

  const handleTab = () => {
    setCouponType(COUPONS_LOTUS_TYPE.OFFLINE)
    localStorage.setItem('refreshCouponLotus', 'true')
  }

  const handleCategoryFilterScollTo = (index: number) => {
    let filterId = ''
    if (index === 0) {
      filterId = '#voucherCategoryFilter'
    }
    if (index === 1) {
      filterId = '#categoryCategoryFilter'
    }
    if (index === 2) {
      filterId = '#productCategoryFilter'
    }

    const categoryFilterSection = document.querySelector(filterId)

    categoryFilterSection?.scrollIntoView({ behavior: 'auto', inline: 'start' })
  }

  const handleScrollToCategorySection = (index: number) => {
    if (couponLotusListComponentRef.current?.handleScrollToCategorySection) {
      couponLotusListComponentRef.current?.handleScrollToCategorySection(index)
    }
  }

  return (
    <div data-testid="coupons-page" id="coupons-page">
      <div id="network-error-container" className={classNames({ hidden: !isNetworkError })}>
        <GeneralError
          idElement='network-error-page'
          title="เครือข่ายขัดข้อง"
          detail="กรุณาตรวจสอบการเชื่อมต่อและลองใหม่อีกครั้ง"
          image={NetworkErrorLogo}
          onClick={() => refreshProfile()}
        ></GeneralError>
      </div>

      <div id="general-error-container" className={classNames({ hidden: !isInternalError })}>
        <GeneralError
          idElement='general-error-page'
          title="ขออภัย มีบางอย่างผิดพลาด"
          detail="กรุณาลองอีกครั้ง"
          image={BadRequestLogo}
          onClick={() => refreshProfile()}
        />
      </div>

      <div className={classNames({ hidden: isNetworkError || isInternalError })}>
        <Tabs
          id="coupon-header-menu"
          className={classNames('max-w-[100vw] overflow-x-hidden')}
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index)
            addGAEvent({
              event: 'mycoupon_header_menu',
              menu: index === 0 ? 'คูปองมายโลตัส' : 'คูปองที่แลกมา'
            })
          }}
        >
          <div
            id="coupon-tab-list-menu"
            className={classNames({
              'h-[48px] transition-all duration-250 ease': !isHideCouponMenu,
              'h-[0px] transition-all duration-250 ease': isHideCouponMenu,
            })}
          >
            <TabList className={classNames('!border-0 !border-b !border-[#E0E0E0]')}>
              <Tab
                className={classNames('react-tabs__tab w-1/2 text-center !py-0 text-gray-inactive')}
                selectedClassName="!text-blue-iris"
                onClick={handleTab}
              >
                <p
                  id={`tab-คูปองมายโลตัส`}
                  className={classNames('text-[18px] font-[700] leading-[28px] py-2', {
                    '!border-b-2 !border-[#00BCB4] inline-flex': tabIndex === 0,
                  })}
                >
                  คูปองมายโลตัส
                </p>
              </Tab>
              <Tab
                className={classNames('react-tabs__tab w-1/2 text-center !py-0 text-gray-inactive')}
                selectedClassName="!text-blue-iris"
                onClick={() => { 
                  const bzbToken = getBZBToken()
                  if(!bzbToken) requestBZBToken(postMessageCounter, setPostMessageCounter)
                  localStorage.setItem('refreshCoupons', 'true')
                  window.parent.postMessage(REQUEST_TOKEN_MESSAGE, '*')
                  setTypeOfPage(COUPONS_TYPE.PREMIUM || COUPONS_TYPE.POWER_UP) 
                }}
              >
                <p
                  id={`tab-คูปองที่แลกมา`}
                  className={classNames('text-[18px] font-[700] leading-[28px] py-2', {
                    '!border-b-2 !border-[#00BCB4] inline-flex': tabIndex === 1,
                  })}
                >
                  คูปองที่แลกมา
                </p>
              </Tab>
            </TabList>
          </div>
          <TabPanel className={classNames('!border-transparent overflow-x-hidden')}>
            <div
              id="couponLotus"
              className={classNames('relative pt-[76px]', {
                'h-[calc(100vh-48px)] flex flex-col': !isHideCouponMenu,
                'h-[calc(100vh)] flex flex-col': isHideCouponMenu,
              })}
            >
              <FilterCoupon
                active={activeCouponType}
                loading={loadingCoupon}
                selectedCouponType={couponType}
                onSelectCouponType={(type) => {
                  setCouponType(type)
                }}
                isHideCouponMenu={isHideCouponMenu}
              />
              <FilterCategoryCoupon
                onClick={(index: number) => {
                  handleScrollToCategorySection(index)
                }}
                active={activeCategory}
                isHideCategoryMenu={isHideCategoryMenu}
                loading={loadingCoupon}
              />
              <CouponsLotusList
                ref={couponLotusListComponentRef}
                loading={loadingCoupon}
                point={profile?.points || 0}
                setSelectedCoupon={(selectedCoupon: Coupon | undefined) => {
                  if(selectedCoupon) {
                    const { couponType } = selectedCoupon

                    if(Object.values(OFFERS_BZB_TYPE).includes(couponType as OFFERS_BZB_TYPE)){
                      setType(couponType as OFFERS_BZB_TYPE)
                    }
                    setSelectedCoupon(selectedCoupon)
                  }
                }}
                setIsShowingSelectedCoupon={setIsShowingSelectedCoupon}
                activeCouponType={couponType}
                setHideCouponType={setHideCouponMenu}
                setHideCategoryCoupon={setHideCategoryMenu}
                isHideCouponMenu={isHideCouponMenu}
                setActiveCategory={setActiveCategory}
                handleCategoryFilterScollTo={handleCategoryFilterScollTo}
                couponDetailId={couponDetailId}
              />
              {Object.values(OFFERS_BZB_TYPE).includes(type as OFFERS_BZB_TYPE) ? (
                  <CouponsSpecialStoreDetails
                    loading={loadingCouponDetail}
                    coupon={selectedCoupon as Coupon}
                    successMessage={message}
                    nodeRef={nodeRef}
                    isShowingSelectedCoupon={isShowingSelectedCoupon}
                    durationTransition={durationTransition}
                  />
                ) : (
                  <CouponsLotusDetails
                    loading={loadingCouponDetail}
                    coupon={selectedCoupon as Coupon}
                    successMessage={message}
                    nodeRef={nodeRef}
                    isShowingSelectedCoupon={isShowingSelectedCoupon}
                    durationTransition={durationTransition}
                  />
                )
              }
         
              <div
                id="custom-alert"
                className={classNames('custom-alert', {
                  hidden: !isShowingBalloon,
                  'is-ios': isIos,
                })}
              >
                <Alert idElement={`${message}-alert-coupon`} description={message} icon="check-white-circle" />
              </div>
            </div>
          </TabPanel>

          <TabPanel className={classNames('!border-transparent overflow-x-hidden')}>
            <div className="h-[calc(100vh-48px)] flex flex-col">
              {(typeOfPage === COUPONS_TYPE.PREMIUM || typeOfPage === COUPONS_TYPE.POWER_UP) && (
                <CouponsV2List
                  idElement="coupons-list"
                  couponId={couponId}
                  loading={loading}
                  point={profile?.points || 0}
                  setSelectedCoupon={(selectedCoupon: Coupon | undefined) => {
                    if(selectedCoupon) {
                      const { couponType } = selectedCoupon
                    
                      if(Object.values(OFFERS_BZB_TYPE).includes(couponType as OFFERS_BZB_TYPE)){
                        setType(couponType as OFFERS_BZB_TYPE)
                      }
                      setSelectedCoupon(selectedCoupon)
                    }
                  }}
                  setIsShowingSelectedCoupon={setIsShowingSelectedCoupon}
                  indexTrigger={indexTrigger}
                />
              )}
              <Transition
                nodeRef={nodeRef}
                in={isShowingSelectedCoupon}
                timeout={500}
                mountOnEnter
                unmountOnExit
              >
                {(state: string) => (
                  <div
                    id="coupons-premium-details-container"
                    ref={nodeRef}
                    className={classNames(
                      'bg-white fixed overscroll-y-contain top-0 bottom-0 left-0 right-0 z-[60] duration-' +
                        durationTransition +
                        ' transition-all translate-x-full overflow-y-auto',
                      {
                        '!translate-x-0': state === 'entering' || state === 'entered',
                      }
                    )}
                  >
                    {Object.values(OFFERS_BZB_TYPE).includes(type as OFFERS_BZB_TYPE) ? (
                      <CouponsSpecialStoreDetails
                        loading={loadingCouponDetail}
                        coupon={selectedCoupon as Coupon}
                        successMessage={message}
                        nodeRef={nodeRef}
                        isShowingSelectedCoupon={isShowingSelectedCoupon}
                        durationTransition={durationTransition}
                      />
                    ) : (
                      <CouponsPremiumDetails
                        idElement="coupons-premium-details"
                        coupon={selectedCoupon as Coupon}
                        successMessage={message}
                      />
                    )}
                  </div>
                )}
              </Transition>
              <div
                className={classNames('custom-alert', {
                  hidden: !isShowingBalloon,
                  'is-ios': isIos,
                })}
              >
                <Alert idElement={`${message}-alert-coupon`} description={message} icon="check-white-circle" />
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Coupons
