import React, { useState } from 'react'
import classNames from 'classnames'
import { ReactBarcode, ReactQRCode } from '../../../common'
import { ReactComponent as QRIcon } from '../../../assets/images/qrcode-icon.svg'
import { ReactComponent as BarcodeIcon } from '../../../assets/images/barcode-icon.svg'
import { Coupon } from '../../../services/my-coupon-bff/types'
import { COUPON_MODE } from '../../../utils/constant'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'

interface IProps {
  coupon: Coupon
  onCopy: Function
}

const BarcodeQRCode: React.FC<IProps> = ({ coupon, onCopy }) => {
  const code = coupon?.couponCode
  const [couponMode, setCouponMode] = useState(COUPON_MODE.BARCODE)

  const copyTextToClipboard = (text: string) => {
    const input = document.createElement('input')
    input.value = text
    input.setAttribute('readonly', 'readonly')
    document.body.appendChild(input)

    input.select()
    input.setSelectionRange(0, input.value.length)
    document.execCommand('copy')
    input.remove()
    onCopy(true)
  }

  return (
    <div>
      <div className='flex justify-center items-center mb-0.5 rounded-t-lg bg-white-smoke'>
        {!code && <p className='text-sm font-lotuss text-gray-inactive my-5'>Couldn't load the Barcode/QRCode</p>}
        {couponMode === COUPON_MODE.BARCODE && code && (
          <div> 
            <ReactBarcode className='px-[8px] mt-[14px] ' value={code} options={{
              background: '#F3F3F3',
              lineColor: '#424242',
              height: 58,
              fontSize: 21.7687,
              font: 'Lotuss Smart HL',
              textMargin: 6,
              margin: 0,
              fontOptions: '500',
              displayValue: false
            }} />
            <div className='flex flex-row justify-center mb-[10px]'>
              <span className='text-base text-gray-primary mr-[4px]'>{code}</span>
              <img
                className="active:opacity-50"
                onClick={() => copyTextToClipboard(code)}
                src="/icons/copy-icon.svg"
                alt="copy-icon"
                data-testid="copy-icon"
                width={18}
              />
            </div>
          </div>
        )}
        {couponMode === COUPON_MODE.QRCODE && code && (
          <div className='h-[112px] flex items-center'>
            <ReactQRCode code={code} options={{ margin: 0, color: { light: '#F3F3F3' } }} />
          </div>
        )}
      </div>

      <div className='flex justify-center items-center rounded-b-lg bg-white-smoke'>
        <button
          className={classNames('flex-1 h-11 leading-7 text-lg font-lotuss font-bold', {
            'text-mint-green fill-mint-green': couponMode === COUPON_MODE.BARCODE,
            'text-gray-secondary': couponMode === COUPON_MODE.QRCODE,
          })}
          onClick={() => {
            setCouponMode(COUPON_MODE.BARCODE)
            addGAEvent({
              event: 'scan_coupon_option',
              coupon_section: coupon?.couponName,
              coupon_type: coupon?.category,
              coupon_scope: coupon?.couponType,
              coupon_kind: coupon?.channel?.join(','),
              scan_option: 'barcode'
            })
          }}
        >
          <BarcodeIcon
            className={classNames('inline-block mr-2', {
              '[&>path]:fill-mint-green': couponMode === COUPON_MODE.BARCODE
            })}
          /> บาร์โค้ด
        </button>

        <div className='w-px h-7 border-r-1 border-[#E0E0E0]'></div>

        <button
          className={classNames('flex-1 h-11 leading-7 text-lg font-lotuss font-bold', {
            'text-mint-green': couponMode === COUPON_MODE.QRCODE,
            'text-gray-secondary': couponMode === COUPON_MODE.BARCODE
          })}
          onClick={() => {
            setCouponMode(COUPON_MODE.QRCODE)
            addGAEvent({
              event: 'scan_coupon_option',
              coupon_section: coupon?.couponName,
              coupon_type: coupon?.category,
              coupon_scope: coupon?.couponType,
              coupon_kind: coupon?.channel?.join(','),
              scan_option: 'QR code'
            })
          }}
        >
          <QRIcon
            className={classNames('inline-block mr-2', {
              '[&>path]:fill-mint-green': couponMode === COUPON_MODE.QRCODE
            })}
          /> คิวอาร์โค้ด
        </button>
      </div>
    </div>
  )
}

export default BarcodeQRCode
