import { CardLoadingOffersSpecialStore } from '../../../../common'
import React from 'react'

interface Props {
  idElement: string
}

const Loading: React.FC<Props> = ({ idElement }) => {
  return (
    <div id={idElement}>
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading0`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading1`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading2`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading3`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading4`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading5`} />
    </div>
  )
}

export default Loading
