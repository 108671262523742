import { addGAEvent } from '../../utils/addGoogleAnalytic'
import classNames from 'classnames'
import * as React from 'react'
import { Fragment } from 'react'

interface Props {
  onClick: Function
  active: number
  isHideCategoryMenu: boolean
  loading: boolean
}

const subCategoryMenuList = [
  { title: 'ส่วนลดเงินสด', trackId: 'voucherCategoryFilter' },
  { title: 'ส่วนลดตามหมวดหมู่', trackId: 'categoryCategoryFilter' },
  { title: 'ส่วนลดสินค้า', trackId: 'productCategoryFilter' },
]

const isExistedSection = (index: number) => {
  const id = index === 0 ? '#voucherSection' : index === 1 ? '#categorySection' : '#productSection'
  return !document.querySelector(`${id}`)
}

const FilterCategoryCoupon: React.FC<Props> = ({
  onClick,
  active,
  isHideCategoryMenu,
  loading,
}) => {
  return (
    <Fragment>
      {
        <div
          id="categoryMenu"
          className={classNames(
            'z-[10] min-h-[76px] flex absolute top-0 left-0 right-0 bg-[#EDFCFC] overflow-x-auto overflow-y-hidden items-center',
            {
              hidden: loading,
              'opacity-0 z-[5] transition-opacity duration-700 ease-in-out': isHideCategoryMenu,
              'transition-opacity duration-700 ease-in-out opacity-100': !isHideCategoryMenu,
            }
          )}
        >
          {subCategoryMenuList.map(({ title, trackId }, index) => {
            return (
              <div
                id={trackId}
                key={index}
                className={classNames('my-[12px]', {
                  '!ml-[12px]': index === 0,
                  '!mr-[12px]': index === 2,
                  'ml-[8px]': index > 0 || index < 2,
                  hidden: isExistedSection(index),
                })}
                onClick={() => {
                  onClick(index)
                  addGAEvent({
                    event: 'mycoupon_sub_category',
                    category: title
                  })
                }}
              >
                <p
                  id={`${title}-title`}
                  key={index}
                  className={classNames(
                    'rounded-[8px] py-[6px] px-[12px] border-1 whitespace-nowrap text-[16px] font-[700]',
                    {
                      'text-[#00BCB4] border-[#00BCB4] bg-[#CEF3F1]': active === index,
                      'text-[#7D7D7D] border-[#E0E0E0] bg-[#FFF]': active !== index,
                    }
                  )}
                >
                  {title}
                </p>
              </div>
            )
          })}
        </div>
      }
    </Fragment>
  )
}

export default FilterCategoryCoupon
