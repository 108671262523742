import React from 'react'

const Loading: React.FC = () => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'
  return (
    <div data-testid="loading-detail-page" className="bg-white fixed overscroll-y-contain top-0 bottom-0 left-0 right-0 z-[60] duration-'500' transition-all translate-x-full overflow-y-auto !translate-x-0">
      <div className='animate-pulse flex flex-col'>
        <div className=' flex items-center justify-center'>
          <div className=' h-[375px] w-full bg-white-smoke' />
        </div>
        <div className='flex flex-col px-3 my-6'>
          <div className='flex flex-col' data-testid='card-detail-detail'>
            <div className="flex text-center w-full mb-4">
              <p className={`${styleSkeleton} rounded-[15px] h-[24px] w-[51px] mr-1`} />
              <p className={`${styleSkeleton} rounded-[15px] h-[24px] w-[51px] mr-1`} />
              <p className={`${styleSkeleton} rounded-[15px] h-[24px] w-[51px] mr-1`} />
            </div>
            <p className={`${styleSkeleton} rounded-[15px] h-[16px] w-10/12 my-1`} />
            <p className={`${styleSkeleton} rounded-[15px] h-[14px] w-7/12 my-1`}/>
          </div>

          <div data-testid='card-detail-detail' className='flex flex-col mt-6'>
            <p className={`${styleSkeleton} rounded-[8px] h-[48px] w-full`}/>
          </div>

          <div data-testid='card-detail-detail' className='flex flex-col mt-6'>
            <p className={`${styleSkeleton} rounded-[8px] h-[158px] w-full`}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
