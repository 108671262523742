import * as React from 'react'

interface Props {
  idElement: string
}

const CardLoading: React.FC<Props> = ({ idElement }) => {
  const styleSkeleton = 'rounded-lg bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div id={idElement} className='animate-pulse flex flex-row p-2 m-2 shadow-3xl shadow-slate-300 rounded-xl border-gray-50'>
      <div id={`${idElement}-h-full`} className='h-full'>
        <p id={`${idElement}-h-full-text`} className={`${styleSkeleton} w-28 h-28`} />
      </div>
      <div id={`${idElement}-w-full-content`} className='flex flex-col w-full ml-2'>
        <div id={`${idElement}-w-full`} className='h-full w-full'>
          <p id={`${idElement}-w-full-text0`} className={`${styleSkeleton} w-full h-2.5 mb-2`} />
          <p id={`${idElement}-w-full-text1`} className={`${styleSkeleton} w-full h-4 mb-2`} />
        </div>
        <div id={`${idElement}-bottom`} className='bottom-0'>
          <p id={`${idElement}-bottom-text0`} className={`${styleSkeleton} w-4/12 h-2.5 mb-2`} />
          <p id={`${idElement}-bottom-text1`} className={`${styleSkeleton} w-8/12 h-2.5`} />
        </div>
      </div>
    </div>
  )
}

export default CardLoading