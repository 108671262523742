import React from 'react'

const EmptyCoupons: React.FC = () => {
  return (
    <div data-testid="empty-page" id="empty-coupons-page" className="flex justify-center items-center flex-1">
      <div className="flex flex-col mb-[48px]">
        <img className="w-40" src="/images/empty-coupon.svg" alt="loading-icon" />
        <p className="font-medium text-base text-gray-empty text-center">ไม่มีคูปองในขณะนี้</p>
      </div>
    </div>
  )
}

export default EmptyCoupons
