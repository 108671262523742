import { Fragment } from 'react'
import { PartnerOfferDetails } from '../../../../services/my-coupon-bff/types'
import { CardDetail, CardDetailLoading } from '../../../../common'
import classNames from 'classnames'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { getDateUnix } from '../../../../utils/date'
import { modifyFullImageUrl } from '../../../../utils/functions'

dayjs.locale('th')
dayjs.extend(buddhistEra)

export interface Props {
  item: PartnerOfferDetails | undefined
  loading: boolean
  isShowModal: boolean
  setIsShowModal: Function
}

const SpecialStoreDetail: React.FC<Props> = ({ item, loading, isShowModal, setIsShowModal }) => {
  const {
    FullImageUrl: image = '', 
    AgencyName: displayName = '',
    Condition: condition = '', 
    Name: name = '', 
    Detail: description = '', 
    PointPerUnit: point = 0,
    Qty: qty = 0,
    StartDate: startDate = 0, 
    ExpireDate: expireDate = 0,
  } = item || {}

  const formattedExpiryDate = getDateUnix(expireDate, 'BZB')
  const formattedStartDate = getDateUnix(startDate, 'BZB')

  return (
    <Fragment>
      {
      loading && !item ? (
        <CardDetailLoading idElement='' />
      ) : (
        <CardDetail
          idElement='offer-details-card-detail'
          image={modifyFullImageUrl(image)}
          name={name}
          startDate={formattedStartDate}
          expiryDate={formattedExpiryDate}
          description={description.replace(/\n/g, '<br/>')}
          condition={condition.replace(/\n/g, '<br/>')}
          showFooterRedeem={true}
          displayName={displayName}
          point={qty}
        >
          <div
            id='offer-details-card-detail-point-inventory-remaining'
            className='flex justify-center items-center p-3 rounded-lg bg-[#EDFCFC]'
          >
            <div
              id='offer-details-card-detail-point-content'
              className='flex flex-col items-center flex-1'
            >
              <p
                id='offer-details-card-detail-point-used'
                className='leading-6 text-base font-lotuss text-gray-secondary'
              >
                จำนวนคอยน์ที่ใช้
              </p>
              <p
                id='offer-details-card-detail-point'
                className='leading-7 text-lg font-bold font-lotuss text-gray-primary'
              >
                {`${point === 0 ? 'ฟรี' : `${point} คอยน์`}`}
              </p>
            </div>
            <div
              id='offer-details-card-detail-inventory-remaining-content'
              className={classNames(
                'flex flex-col items-center border-l-1 flex-1',
                {
                  hidden: !qty && qty !== 0,
                }
              )}
            >
              <p
                id='offer-details-card-detail-number-permissions'
                className='leading-6 text-base font-lotuss text-gray-secondary'
              >
                จำนวนสิทธิ์
              </p>
              <p
                id='offer-details-card-detail-inventory-remaining'
                className='leading-7 text-lg font-bold font-lotuss text-gray-primary'
              >
                {qty}
              </p>
            </div>
          </div>
        </CardDetail>
      )}
    </Fragment>
  )
}

export default SpecialStoreDetail
