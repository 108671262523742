import * as React from 'react'
import classNames from 'classnames'
import { OFFERS_SPECIAL_STORE_STATUS } from '../../utils/status'
import './Card.css'
import { mappingImageDefault } from '../../utils/mapper'

export type Props = {
  idElement: string
  company?: string
  title?: string
  date?: string
  status?: OFFERS_SPECIAL_STORE_STATUS
  image?: string
  loading?: boolean
  qty?: number
}

export enum STATUS {
  EMPTY = 'สิทธิ์หมด',
  NOT_ENOUGH = 'คอยน์ไม่เพียงพอ',
  REMAINDER = 'สิทธิ์สุดท้าย',
}

const CardOffersSpecialStore: React.FC<Props> = ({ idElement, company, title, date, status, image, loading, qty }) => {
  const isDisable = status && STATUS[status] && status !== OFFERS_SPECIAL_STORE_STATUS.REMAINDER
  const img = image ? mappingImageDefault(image) : undefined
  return (
    <div
      id={idElement}
      className={classNames(
        'flex flex-row p-2.5 shadow-3xl rounded-xl mt-[16px] mx-3 border-gray-50 min-h-30 max-h-30',
        { 'opacity-75': loading }
      )}
    >
      <div
        id={`${idElement}-content`}
        className={classNames(
          'rounded-lg max-w-26 bg-gray-empty flex items-center justify-center mr-2',
          { 'opacity-40': isDisable }
        )}
      >
        {image ? (
          <img
            id={`${idElement}-image`}
            data-testid="card-image"
            src={img}
            className="rounded-lg w-26 max-h-26"
            alt="card"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/icons/coupon-image-placeholder.svg'
              currentTarget.className = 'rounded-lg w-26'
            }}
          />
        ) : (
          <img
            id={`${idElement}-image`}
            src="/icons/coupon-image-placeholder.svg"
            alt="img-placeholder"
            className="rounded-lg w-26"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/icons/coupon-image-placeholder.svg'
              currentTarget.className = 'rounded-lg w-26'
            }}
          />
        )}
      </div>
      <div id={`${idElement}-content-detail`} className="flex flex-col w-4/6 bg-blue font-lotuss">
        <div id={`${idElement}-detail`} data-testid="card-detail" className="h-full mb-2">
          <p
            id={`${idElement}-text-company`}
            data-testid="card-company"
            className={classNames('text-xs leading-4.5 tracking-wide font-medium', {
              'text-gray-secondary': !isDisable,
              'text-gray-inactive': isDisable,
            })}
          >
            {company}
          </p>
          <p
            id={`${idElement}-text-title`}
            data-testid="card-title"
            className={classNames('w3/4 truncate-2-lines text-base font-bold',
            {
              'text-gray-primary': !isDisable,
              'text-gray-secondary': isDisable,
            })}
          >
            {title}
          </p>
        </div>
        <div id={`${idElement}-status`} className="bottom-0 bg-red">
          <p
            id={`${idElement}-text-status`}
            data-testid="card-status"
            className={classNames('text-[12px] leading-[18px] font-medium font-lotuss text-danger tracking-wide', {
              'text-black text-opacity-0': !status,
              hidden: loading,
            })}
          >
            {status ? status === OFFERS_SPECIAL_STORE_STATUS.REMAINDER ? `${qty} ${STATUS[status]}` : STATUS[status] : ''}
          </p>
          <p id={`${idElement}-text-date`} className="text-xs leading-4.5 font-medium text-gray-inactive font-lotuss tracking-wide">
            หมดเขต {date}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CardOffersSpecialStore
