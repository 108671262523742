interface InitOptions {
  code: number
  refCode: string
}

interface Reasons { reasons: string }

interface Error {
  error_name: string,
  error_detail: [Reasons]
}

export interface ErrorResponse {
  code: number
  refCode?: string
  error?: Error
}

export class HttpErrorResponse implements InitOptions {
  code: number
  refCode: string
  error?: Error

  constructor(code: number, refCode: string, error?: Error) {
    this.code = code
    this.refCode = refCode
    this.error = error
  }
  toResponseObject() {
    return {
      code: this.code,
      refCode: this.refCode,
      error: this.error
    } as ErrorResponse
  }
}

