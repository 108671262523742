function getAccessToken() {
  return localStorage.getItem('token')
}

export interface HttpResponse {
  httpStatusCode: number
  json: any
}

const validateExistingToken = () => {
  const accessToken = getAccessToken()
  if (!accessToken) window.parent.postMessage('refresh-token', '*')
  return accessToken ? true : false
}

const handdleErrorToken = (status: number) => {
  if (status === 401 && window) {
    window.parent.postMessage('refresh-token', '*')
  }
}

export const get = async (url: string, options?: RequestInit): Promise<HttpResponse> => {
  if (!validateExistingToken()) {
    return {
      httpStatusCode: 401,
      json: {},
    }
  }
  const controller = new AbortController()

  if (!options) {
    options = { headers: { Authorization: '' } }
  }

  options.method = 'GET'
  options.signal = controller.signal
  options.headers = { ...options.headers, Authorization: getAccessToken()! }

  try {
    const response = await fetch(url, options)
    const json = await response.json()

    handdleErrorToken(response.status)

    return {
      httpStatusCode: response.status,
      json,
    }
  } catch (e) {
    throw e
  }
}

export const post = async (url: string, options?: RequestInit): Promise<HttpResponse> => {
  if (!validateExistingToken()) {
    return {
      httpStatusCode: 401,
      json: {},
    }
  }
  const controller = new AbortController()

  if (!options) {
    options = { headers: { Authorization: '' } }
  }

  options.method = 'POST'
  options.signal = controller.signal
  options.headers = { ...options.headers, Authorization: getAccessToken()! }

  try {
    const response = await fetch(url, options)
    const json = await response.json()

    handdleErrorToken(response.status)

    return {
      httpStatusCode: response.status,
      json,
    }
  } catch (e) {
    throw e
  }
}

export const del = async (url: string, options?: RequestInit): Promise<HttpResponse | undefined> => {
  if (!validateExistingToken()) {
    return {
      httpStatusCode: 401,
      json: {},
    }
  }
  const controller = new AbortController()

  if (!options) {
    options = { headers: { Authorization: '' } }
  }
  options.method = 'DELETE'
  options.signal = controller.signal
  options.headers = { ...options.headers, Authorization: getAccessToken()! }

  try {
    const response = await fetch(url, options)
    const json = await response.json()

    handdleErrorToken(response.status)

    return {
      httpStatusCode: response.status,
      json,
    }
  } catch (e) {
    throw new Error('พบข้อผิดพลาดบางประการ กรุณาลองใหม่อีกครั้ง')
  }
}

export const patch = async (url: string, options?: RequestInit): Promise<HttpResponse> => {
  if (!validateExistingToken()) {
    return {
      httpStatusCode: 401,
      json: {},
    }
  }
  const controller = new AbortController()

  if (!options) {
    options = { headers: { Authorization: '' } }
  }
  options.method = 'PATCH'
  options.signal = controller.signal
  options.headers = { ...options.headers, Authorization: getAccessToken()! }

  try {
    const response = await fetch(url, options)
    const json = await response.json()

    handdleErrorToken(response.status)
    
    return {
      httpStatusCode: response.status,
      json,
    }
  } catch (e) {
    throw new Error('พบข้อผิดพลาดบางประการ กรุณาลองใหม่อีกครั้ง')
  }
}
