export const getLocalStorageJson = (key: string) => {
	return localStorage?.getItem(key) ? JSON.parse(localStorage?.getItem(key) as string) : undefined
}

export const getLocalStorageString = (key: string) => {
	return localStorage?.getItem(key) as string
}

export const setLocalStorage = (key: string, value: any) => {
	return localStorage?.setItem(key, JSON.stringify(value))
}

export const removeLocalStorage = (key: string) => {
	return localStorage?.removeItem(key)
}