const wnd: any = window

export function isDeviceRunningiOS() {
    var userAgent = navigator.userAgent || navigator.vendor || wnd.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !wnd.MSStream) {
        return true;
    } else {
        return false;
    }
}
