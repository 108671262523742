import * as React from 'react'

interface Props {}

const SortingOfferSpecialStore: React.FC<Props> = () => {
  return (
    <div className='flex flex-row mx-3 my-4 border-1 rounded-lg border-gray-[#6B6B6B] text-base'>
      <button type='button' className='p-1.5'>Sorting and shortcut filters</button>
    </div>
  )
}

export default SortingOfferSpecialStore