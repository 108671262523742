import { addGAEvent } from '../../../utils/addGoogleAnalytic'
import { Modal } from '../../../common'
import { ModalProps } from './CouponsLotusDetails.model'

export const ModalConfirmCopyCode = ({ idElement, isShowModal, setIsShowModal }: ModalProps) => {
  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <img src={`/icons/checked-circle.svg`} alt='checked-circle-icon' className='w-[53px] ml-[29px] mt-[37px]' />
      <div className='p-6'>
        <p className='text-2xl leading-9 font-lotuss text-gray-primary font-bold tracking-wide1'>คัดลอกโค้ดสำเร็จ</p>
      </div>
      <div className='flex flex-col px-6'>
        <button
          type='button'
          className='text-white text-lg font-lotuss font-bold p-3 mb-2 rounded-lg bg-blue-iris'
          onClick={() => {
            addGAEvent({
              event: 'mycoupon_go_to_lotuss_app'
            })
            if (window.parent) {
              window.parent.postMessage({
                topic: 'open-lotuss-app',
                url: process.env.REACT_APP_DOWNLOAD_LOTUSS!
              }, '*')
            }
          }}>ไปยัง โลตัส แอป
        </button>
        <button
          type='button'
          className='text-blue-iris text-lg font-lotuss font-bold p-3 rounded-lg'
          onClick={() => {
            setIsShowModal(!isShowModal)
            addGAEvent({
              event: 'mycoupon_not_now'
            })
          }}>ไม่ใช่ตอนนี้
        </button>
      </div>
    </Modal>
  )
}