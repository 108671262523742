export const getDisplayCoin = (coin: number) => {
  if (coin === 0) return '0.00'

  const coinSeparated = JSON.stringify(coin).split('.')
  let coinWithDecimal!: number

  if (coinSeparated.length > 1) {
    const decimalCoin = coinSeparated[1]?.slice(0, 2)
    coinWithDecimal = Number(`${coinSeparated[0]}.${decimalCoin}`)
  }

  return new Intl.NumberFormat('th', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(coinWithDecimal || coin)
}

export function modifyFullImageUrl(url: string) {
  return url.replace('?', '-large?');
}