import * as React from 'react'

interface Props {
  idElement: string
}

const CardLoadingOffersSpecialStore: React.FC<Props> = ({ idElement }) => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div id={idElement} className='animate-pulse flex flex-row py-2 px-2.5 my-4 mx-3 shadow-3xl shadow-slate-300 rounded-xl border-gray-50'>
      <div id={`${idElement}-h-full`} className='h-full'>
        <p id={`${idElement}-h-full-text`} className={`${styleSkeleton} rounded-lg w-28 h-28`} />
      </div>
      <div id={`${idElement}-w-full-content`} className='flex flex-col w-full ml-2'>
        <div id={`${idElement}-w-full`} className='h-full w-full'>
          <div className="flex flex-row justify-between">
            <p id={`${idElement}-w-full-text0`} className={`${styleSkeleton} rounded-[15px] w-1/2 h-3`} />
            <p id={`${idElement}-w-full-text1`} className={`${styleSkeleton} rounded w-5 h-5`} />
          </div>
          <p id={`${idElement}-w-full-text2`} className={`${styleSkeleton} rounded-[15px] w-5/6 h-4`} />
        </div>
        <div id={`${idElement}-bottom`} className='bottom-0'>
          <p id={`${idElement}-bottom-text0`} className={`${styleSkeleton} rounded-[15px] w-2/5 h-3 mb-2`} />
          <p id={`${idElement}-bottom-text1`} className={`${styleSkeleton} rounded-[15px] w-3/4 h-3`} />
        </div>
      </div>
    </div>
  )
}

export default CardLoadingOffersSpecialStore