import { COUPONS_LOTUS_TYPE } from '../../utils/constant'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import FilterCouponLotusType from './FilterCouponLotusType'

interface Props {
  active: string
  isHideCouponMenu: boolean
  loading: boolean
  onSelectCouponType: (type: string) => void
  selectedCouponType: string
}
const FilterCoupon: React.FC<Props> = ({
  active,
  isHideCouponMenu,
  loading,
  onSelectCouponType,
  selectedCouponType,
}) => {
  const [activeCouponType, setActiveCouponType] = useState<string>(COUPONS_LOTUS_TYPE.OFFLINE)

  const selectedCouponTypeOption = (trackId: string) => {
    if (trackId === COUPONS_LOTUS_TYPE.OFFLINE) {
      return COUPONS_LOTUS_TYPE.OFFLINE
    }
    if (trackId === COUPONS_LOTUS_TYPE.ONLINE) {
      return COUPONS_LOTUS_TYPE.ONLINE
    }
    if (trackId === COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU) {
      return COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU
    }
  }

  useEffect(() => {
    if (selectedCouponType === COUPONS_LOTUS_TYPE.OFFLINE) {
      setActiveCouponType(COUPONS_LOTUS_TYPE.OFFLINE)
    } else if (selectedCouponType === COUPONS_LOTUS_TYPE.ONLINE) {
      setActiveCouponType(COUPONS_LOTUS_TYPE.ONLINE)
    } else if (selectedCouponType === COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU) {
      setActiveCouponType(COUPONS_LOTUS_TYPE.SPECIAL_FOR_YOU)
    }
  }, [selectedCouponType])

  return (
    <div
      id="couponTypeMenu"
      className={classNames('z-[10] bg-white overflow-x-auto absolute top-0 left-0 right-0', {
        hidden: active !== COUPONS_LOTUS_TYPE.OFFLINE && !loading,
        'min-h-[76px] flex transition-opacity ease-in-out duration-500': !isHideCouponMenu,
        'opacity-0 z-[5] flex transition-all ease-in-out delay-500 duration-500': isHideCouponMenu,
        'pt-[16px] pb-[24px]': !loading && !isHideCouponMenu,
      })}
    >
      <FilterCouponLotusType
        active={activeCouponType}
        onClick={(trackId: string) => {
          const type = selectedCouponTypeOption(trackId)

          onSelectCouponType(type ?? COUPONS_LOTUS_TYPE.OFFLINE)
          setActiveCouponType(trackId)
        }}
        loading={loading}
      />
    </div>
  )
}

export default FilterCoupon
