import * as React from 'react'

export interface Props {
  idElement: string
  description: string
  icon: string
}

const Alert: React.FC<Props> = ({ idElement, description, icon }) => {
  return (
    <div id={idElement} className='px-4 fixed flex flex-col w-full z-[1]'>
      <div
        id={`${idElement}-content`}
        className='mt-2 inline-flex w-full rounded-lg bg-[#3D4245] p-3.5 text-base text-white dark:bg-[#3D4245]'
        role='alert'>
        <img id={`${idElement}-icon`} src={`/icons/${icon}.svg`} alt='icon' className='mr-2 content-center w-4'/>
        <p id={`${idElement}-text`} className='text-sm'>{description}</p>
      </div>
    </div>
  )
}

export default Alert