import React, { Fragment, useEffect, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import { Card } from '../../../common'
import { getBZBCoupons, getCouponsPowerUpAndPremium } from '../../../services/my-coupon-bff'
import { Coupon, CouponsBzbDetails } from '../../../services/my-coupon-bff/types'
import { getParam } from '../../../utils/params'
import { DATE_FORMAT, getDate } from '../../../utils/date'
import { getStatus } from '../../../utils/status'
import { Error as ErrorPremiumList } from '../../../common'
import EmptyCoupons from './EmptyCoupons'
import { ErrorResponse } from '../../../utils/reponseType'
import Loading from '../Loading'
import classNames from 'classnames'
import { LOTUS } from '../../../utils/constant'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'
import { getOfferType, transformToCoupon, requestBZBToken, TOKEN_RECEIVED_MESSAGE, REQUEST_TOKEN_MESSAGE } from '../../../components/functions'
import { ERROR_GENERAL } from '../../../utils/error'
import { getLocalStorageString } from '../../../utils/storage'

export interface Props {
  loading: boolean
  point: number
  couponId?: string
  setSelectedCoupon: (selectedCoupon: Coupon | undefined) => void
  setIsShowingSelectedCoupon: (value: boolean) => void
  indexTrigger: number // use for trigger index to be not -1
  idElement: string
}

const CouponsV2List: React.FC<Props> = ({
  loading,
  point,
  couponId,
  indexTrigger,
  setSelectedCoupon,
  setIsShowingSelectedCoupon,
  idElement,
}) => {
  const [isLoading, setLoading] = useState<boolean>(loading)
  const [isLoadingBZB, setLoadingBZB] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [BZBError, setBZBError] = useState<ERROR_GENERAL>()
  const [error, setError] = useState<ErrorResponse>()
  const [coupons, setCoupons] = useState<Coupon[]>()
  const [couponsPremium, setCouponsPremium] = useState<Coupon[]>()
  const [couponsBzbList, setCouponsBzbList] = useState<Coupon[]>()
  const [activeCouponIndex, setActiveCouponIndex] = useState<number>(-1)
  const refreshCoupons = localStorage.getItem('refreshCoupons')
  const [postMessageCounter, setPostMessageCounter] = useState<number>(0)

  const handleOpenOfferDetail = (index: number) => {
    setActiveCouponIndex(index)
  }

  function handleSelectedOffer(coupons: Coupon[]) {
    let requestedOfferIndex = coupons.findIndex((coupons) => coupons.couponId === couponId)
    if (requestedOfferIndex) {
      handleOpenOfferDetail(requestedOfferIndex)
    }
  }

  const fetchCoupons = useCallback(async() => {
    try {
      setLoading(true)
      let token: string = getParam('token') || localStorage?.getItem('token') || ''
      const response = await getCouponsPowerUpAndPremium(token)

      if (response) {
        const result = response as Coupon[] | []

        if (couponId) {
          handleSelectedOffer(result)
        }
        setLoading(false)
        setIsError(false)
        setCouponsPremium(result)
      }
    } catch (e) {
      setError(e as ErrorResponse)
      setLoading(false)
      setIsError(true)
    }
  }, [couponId, handleSelectedOffer])

  const fetchCouponsSpecialStore = useCallback(async() => {
    try {
      setLoadingBZB(true)
      setBZBError(undefined)

      const bzbToken = getLocalStorageString('bzb_user_token')
      const response = await getBZBCoupons(bzbToken) as CouponsBzbDetails[]

      if (response) {
        let result: Coupon[] = []
        response.forEach((data) => {
          const offerType = getOfferType(data.Type, data.MinutesValidAfterUsed)
          result.push(transformToCoupon(data as CouponsBzbDetails, offerType)) 
        })

        if (couponId) {
          handleSelectedOffer(result)
        }
        setLoadingBZB(false)
        setCouponsBzbList(result)
      }
    } catch (e: any) {
      const errorCode = e?.code

      if (errorCode === ERROR_GENERAL.TOKEN_EXPIRED) {
        setBZBError(ERROR_GENERAL.TOKEN_EXPIRED)
        if (postMessageCounter < 5) {
          requestBZBToken(postMessageCounter, setPostMessageCounter)
        } else { 
          setBZBError(undefined)
        }
      } else {
        setBZBError(undefined)
      }
      setLoadingBZB(false)
    }
  }, [couponId, handleSelectedOffer, postMessageCounter])

  useEffect(() => {
    if(
      (couponsBzbList && !BZBError && !isLoadingBZB) &&
      (couponsPremium && !isError && !isLoading)
    ) {
      const sortedCoupons =  getSortedCouponsOrderByAcquireDateDesc([
        ...(couponsBzbList || []),
        ...(couponsPremium || []),
      ])
      setCoupons(sortedCoupons)
    }
  }, [
    BZBError,
    couponsBzbList,
    couponsPremium,
    isError,
    isLoading,
    isLoadingBZB,
  ])

  const getCoupons = useCallback(async() => {
    await fetchCoupons()
    await fetchCouponsSpecialStore()
  }, [fetchCoupons, fetchCouponsSpecialStore])

  const getCouponsBzb = useCallback(async() => {
    await fetchCouponsSpecialStore()
  }, [fetchCouponsSpecialStore])

  const handleCloseCouponDetail = useCallback(() => {
    setActiveCouponIndex(-1)
    handlePathUrl(-1)
    setIsShowingSelectedCoupon(false)
    localStorage.setItem('refreshCoupons', 'true')
  }, [setIsShowingSelectedCoupon])

  const handleParentEvent = useCallback((event: MessageEvent) => {
    if (event.data === 'iframe:navigate_back') {
      window.parent.postMessage(REQUEST_TOKEN_MESSAGE, '*')
      if (activeCouponIndex === -1) {
        if (window.parent) window.parent.postMessage('parent:navigate_back', '*')
      } else {
        handleCloseCouponDetail()
      }
    }

    if (typeof event.data === 'string') {
      if (event.data.match(TOKEN_RECEIVED_MESSAGE)) {
        const token = JSON.parse(event.data).token

        if (token) {
          localStorage.setItem('bzb_user_token', token)
        } else {
          requestBZBToken(postMessageCounter, setPostMessageCounter)
        }
      }
    }
  }, [activeCouponIndex, handleCloseCouponDetail, postMessageCounter])

  useEffect(() => {
    if ((BZBError === ERROR_GENERAL.TOKEN_EXPIRED && postMessageCounter !== 5) && !couponsBzbList) {
      getCouponsBzb()
    }
  }, [postMessageCounter, BZBError, getCouponsBzb, couponsBzbList])

  useEffect(() => {
    if (refreshCoupons) {
      getCoupons()
      localStorage.removeItem('refreshCoupons')
    }
  }, [refreshCoupons, getCoupons, isLoading])

  useEffect(() => {
    setActiveCouponIndex(indexTrigger)
  }, [indexTrigger])

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleParentEvent)
    }

    return () => {
      window.removeEventListener('message', handleParentEvent)
    }
  }, [handleParentEvent])

  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage('iframe:ready', '*')
    }
  }, [])

  const handleOpenCouponDetail = (index: number, id?: string) => {
    setActiveCouponIndex(index)
    handlePathUrl(index, id)
    setSelectedCoupon(coupons![index])
    setIsShowingSelectedCoupon(true)
    addGAEvent({
      event: 'mycoupon_card',
      coupon_section: coupons![index]?.couponName,
      coupon_type: coupons![index]?.category,
      coupon_scope: coupons![index]?.couponType,
      coupon_kind: coupons![index]?.channel?.join(',')
    })
  }

  const handlePathUrl = (index: number, id?: string) => {
    const urlArray = window.location.href.split('?')

    if (index !== -1) {
    } else {
      const url = urlArray[0].split('/')
      url.pop()
    }
  }

  const getSortedCouponsOrderByAcquireDateDesc = (coupons: Coupon[]) => {
    if (coupons) {
      return coupons.sort((a, b) => {
        return (a.acquireDate && !b.acquireDate) ||
          dayjs(a.acquireDate).isAfter(dayjs(b.acquireDate))
          ? -1
          : 1
      })
    }
    return coupons
  }

  if (isLoading || isLoadingBZB) {
    return <Loading idElement="coupons-premium-list-loading" />
  }

  if (isError) {
    if (error?.code === 401) {
      // fetchCoupons()
      return <Loading idElement="coupons-premium-list-loading" />
    } else {
      return (
        <div className="flex-1 flex justify-center item-center mb-[125px]">
          <ErrorPremiumList
            idElement={`${idElement}-error`}
            onClick={fetchCoupons}
            status={error?.code || 500}
            refCode={error?.refCode || ''}
          />
        </div>
      )
    }
  }

  if (!coupons?.length) {
    return <EmptyCoupons />
  }

  return (
    <div
      id="coupons-premium-list-page"
      data-testid="coupons-premium-list-page"
      className={classNames('flex flex-col mt-2 pb-[16px] h-[calc(100vh-48px)] overflow-y-auto')}
    >
      {coupons.map(({ couponId: id, couponName, expiryDate, media }, index) => {
        const date = getDate(expiryDate, DATE_FORMAT)
        const status = getStatus(expiryDate, point, 0)

        return (
          <Fragment key={id}>
            <div
              data-testid={`card-component-${id}`}
              id={`card-component-${id}`}
              onClick={() => handleOpenCouponDetail(index, id)}
            >
              <Card
                idElement={idElement}
                key={id}
                company={LOTUS}
                title={couponName}
                date={date}
                status={status}
                image={media.url}
              />
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default CouponsV2List
