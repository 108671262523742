import Loading from './Loading'
import EmptySpecialStoreList from './EmptySpecialStoreList'
import { Fragment } from 'react'
import classNames from 'classnames'
import { PartnerOffers } from '../../../../services/my-coupon-bff/types'
import { CardOffersSpecialStore } from '../../../../common'
import { DATE_FORMAT, getDateUnix } from '../../../../utils/date'
import { getStatusOffersSpecialStore } from '../../../../utils/status'

export interface Props {
  list: PartnerOffers[]
  loading: boolean
  point?: number
  onClick?: (id: number) => void
}

const SpecialStoreList: React.FC<Props> = ({ list, loading, point, onClick }) => {
  if (loading) {
    return <Loading idElement="offers-special-store-loading" />
  }

  if (!list?.length && !localStorage.getItem('search')) {
    return <EmptySpecialStoreList />
  }

  if (!list?.length && localStorage.getItem('search')) {
    return <></>
  }

  return (
    <>
      {list?.length &&
        list.map((item, index) => {
          const date = item.ExpireDate ? getDateUnix(item.ExpireDate, 'BZB', DATE_FORMAT) : ''
          const status = getStatusOffersSpecialStore(point, item.PointPerUnit, item.Qty)
          return (
            <Fragment key={item.ID}>
              <div
                id={`offers-special-store-list-content${index}`}
                onClick={() => {
                  if(item.ID && onClick) {
                    onClick(item.ID)
                  }
                }}
                className={classNames({
                  'pb-[68px]': index === list.length - 1,
                })}
              >
                <CardOffersSpecialStore
                  idElement={`offers-special-store-list-card${index}`}
                  key={item.ID}
                  company={item.AgencyName}
                  title={item.Name}
                  date={date}
                  status={status}
                  image={item.FullImageUrl}
                  loading={loading}
                  qty={item.Qty}
                />
              </div>
            </Fragment>
          )
        })}
    </>
  )
}

export default SpecialStoreList
