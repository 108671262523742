import { Coupon } from '../../../services/my-coupon-bff/types'
import { CardDetail, ReactBarcode, ReactQRCode } from '../../../common'
import { DATE_FORMAT, getDate } from '../../../utils/date'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ReactComponent as QRIcon } from '../../../assets/images/qrcode-icon.svg'
import { ReactComponent as BarcodeIcon } from '../../../assets/images/barcode-icon.svg'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'

export type Props = {
  idElement: string
  coupon: Coupon
  successMessage?: string
}

enum COUPON_MODE {
  BARCODE = 'BARCODE',
  QRCODE = 'QRCODE',
}

const CouponsPremiumDetails: React.FC<Props> = ({ idElement, coupon, successMessage }) => {
  const acquireDate = coupon.acquireDate ? getDate(coupon.acquireDate || '', DATE_FORMAT) : '-'
  const [couponMode, setCouponMode] = useState<COUPON_MODE>(COUPON_MODE.BARCODE)
  const code = coupon.couponCode
  const [showSuccessMessage, setShowSuccessMessage] = useState<string>(successMessage || '')

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage('')
        localStorage?.setItem('successMessage', '')
      }, 3000)
    }
  }, [showSuccessMessage])

  return (
    <CardDetail
      idElement={`${idElement}-card-detail`}
      image={coupon.media.url}
      name={coupon.couponName}
      startDate={coupon.startDate}
      expiryDate={coupon.expiryDate}
      description={coupon.description.replace(/\n/g, '<br/>')}
      condition={coupon.condition.replace(/\n/g, '<br/>')}
    >
      <div className="flex justify-center items-center py-3 my-2 rounded-lg bg-white-smoke">
        <p id="card-detail-date" className="m-0 text-base leading-6 text-gray-primary font-bold font-lotuss">
          วันที่แลกใช้ : {acquireDate}
        </p>
      </div>

      <div className="flex justify-center items-center mt-1 rounded-t-lg bg-white-smoke">
        {!code && (
          <p id="card-detail-show-barcode-error" className="text-sm font-lotuss text-gray-inactive my-5">
            Couldn't load the Barcode/QRCode
          </p>
        )}
        {couponMode === COUPON_MODE.BARCODE && code && (
          <ReactBarcode
            className="px-[38px] h-[112px]"
            value={code}
            options={{
              background: '#F3F3F3',
              lineColor: '#424242',
              height: 76,
              fontSize: 21.7687, // there is some scaling 73.6% of the defined value (73.6% of 21.7687px = 16px)
              font: 'Lotuss Smart HL',
              textMargin: 6,
              margin: 0,
              fontOptions: '500',
            }}
          />
        )}
        {couponMode === COUPON_MODE.QRCODE && code && (
          <div className="h-[112px] flex items-center">
            <ReactQRCode code={code} options={{ margin: 0, color: { light: '#F3F3F3' } }} />
          </div>
        )}
      </div>

      <div className="flex justify-center items-center mt-1 rounded-b-lg bg-white-smoke">
        <button
          className={classNames('flex-1 h-11 leading-7 text-lg font-lotuss font-bold', {
            'text-mint-green fill-mint-green': couponMode === COUPON_MODE.BARCODE,
            'text-gray-secondary': couponMode === COUPON_MODE.QRCODE,
          })}
          onClick={() => {
            setCouponMode(COUPON_MODE.BARCODE)
            addGAEvent({
              event: 'scan_coupon_option',
              coupon_section: coupon?.couponName,
              coupon_type: coupon?.category,
              coupon_scope: coupon?.couponType,
              coupon_kind: coupon?.channel?.join(','),
              scan_option: 'barcode'
            })
          }}
        >
          <BarcodeIcon
            className={classNames('inline-block mr-2', {
              '[&>path]:fill-mint-green': couponMode === COUPON_MODE.BARCODE,
            })}
          />{' '}
          บาร์โค้ด
        </button>

        <div className="w-px h-7 border-r-1 border-[#E0E0E0]"></div>

        <button
          className={classNames('flex-1 h-11 leading-7 text-lg font-lotuss font-bold', {
            'text-mint-green': couponMode === COUPON_MODE.QRCODE,
            'text-gray-secondary': couponMode === COUPON_MODE.BARCODE,
          })}
          onClick={() => {
            setCouponMode(COUPON_MODE.QRCODE)
            addGAEvent({
              event: 'scan_coupon_option',
              coupon_section: coupon?.couponName,
              coupon_type: coupon?.category,
              coupon_scope: coupon?.couponType,
              coupon_kind: coupon?.channel?.join(','),
              scan_option: 'QR code'
            })
          }}
        >
          <QRIcon
            className={classNames('inline-block mr-2', {
              '[&>path]:fill-mint-green': couponMode === COUPON_MODE.QRCODE,
            })}
          />{' '}
          คิวอาร์โค้ด
        </button>
      </div>
    </CardDetail>
  )
}

export default CouponsPremiumDetails
