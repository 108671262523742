import classNames from 'classnames'
import React, { useState } from 'react'
import Alert from '../alert'
import { isDeviceRunningiOS } from '../../utils/validator'

export interface Props {
  idElement: string
  onClick: Function
  refCode: string
  status: number
  isFooter?: boolean
}

const Error: React.FC<Props> = ({ idElement, onClick, refCode, status, isFooter }) => {
  const [message, setMessage] = useState<string>('')
  const [isShowingBalloon, setIsShowingBalloon] = useState<boolean>(false)
  const isIos = isDeviceRunningiOS()

  function copyTextToClipboard(text: string) {
    const input = document.createElement('input')
    input.value = text
    input.setAttribute('readonly', 'readonly')
    document.body.appendChild(input)

    input.select()
    input.setSelectionRange(0, input.value.length)
    document.execCommand('copy')
    input.setAttribute('style', 'display:none')

    animation()
  }

  function showBalloon() {
    setIsShowingBalloon(true)
    setTimeout(() => {
      setIsShowingBalloon(false)
    }, 3000)
  }

  function animation() {
    setMessage('คัดลอกโค้ดสำเร็จ')
    showBalloon()
  }

  return (
    <div
      id={idElement}
      data-testid="error-page"
      className={classNames('flex flex-col justify-center items-center overflow-hidden', { 'h-[calc(100%-54px)]': isFooter })}
    >
      <img
        id={`${idElement}-image`}
        data-testid="error-image"
        className="w-40"
        src="/images/bad-request.svg"
        alt="error-img"
      />
      <p id={`${idElement}-title`} data-testid="error-title" className="text-center text-base text-gray-empty">
        ไม่สามารถโหลดข้อมูลได้
        <br />
        กรุณาโหลดหน้าใหม่ อีกครั้ง ({status})
      </p>
      <div id={`${idElement}-ref-code`} className="flex flex-row">
        <p id={`${idElement}-ref-code-text`} data-testid="error-ref-code" className="text-center text-xs text-gray-empty pt-2">
          Ref code: {refCode}
        </p>
        <img
          id={`${idElement}-ref-code-icon`}
          className="m-1.5 w-4.5 p-0.5 active:opacity-50"
          onClick={() => copyTextToClipboard(refCode)}
          src="/icons/copy-icon.svg"
          alt="copy-icon"
          data-testid="copy-icon"
        />
      </div>
      <div id={`${idElement}-try-again`} data-testid="error-try-again" className="flex flex-row m-1" onClick={() => onClick()}>
        <img id={`${idElement}-try-again-icon`} className="m-2 w-6" src="/icons/reload-icon.svg" alt="loading-icon" />
        <p id={`${idElement}-try-again-text`} className="text-center text-sm text-blue-iris pt-2">ลองอีกครั้ง</p>
      </div>
      <div
        id={`${idElement}-alert-container`}
        className={classNames('custom-alert w-screen mb-[16px]', {
          hidden: !isShowingBalloon,
          'is-ios': isIos,
        })}
      >
        <Alert idElement={`${idElement}-alert`} description={message} icon="check-white-circle" />
      </div>
    </div>
  )
}

export default Error
