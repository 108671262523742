import { CardLoadingOffersSpecialStore } from '../../../../common'
import React from 'react'

interface Props {
  idElement: string
}

const Loading: React.FC<Props> = ({ idElement }) => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'
  return (
    <div id={idElement} className="h-full">
      <div className="animate-pulse flex flex-row py-2  my-4 mx-3 rounded-xl border-gray-50">
        <p
          id={`${idElement}-w-full-text2`}
          className={`${styleSkeleton} mr-[8px] rounded-[15px] w-[144px] h-4`}
        />
        <p
          id={`${idElement}-w-full-text2`}
          className={`${styleSkeleton}  rounded-[15px] w-[64px] h-4`}
        />
      </div>
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading0`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading1`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading2`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading3`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading4`} />
      <CardLoadingOffersSpecialStore idElement={`${idElement}-card-loading5`} />
    </div>
  )
}

export default Loading
