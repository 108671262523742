import classNames from 'classnames'
import React from 'react'

export interface Props {
  idElement: string
  onClick: Function
  title: string
  detail: string
  image: string
}

const GeneralError: React.FC<Props> = ({ idElement, onClick, title, detail, image }) => {
  return (
    <div id={idElement} data-testid='error-page' className={classNames('flex justify-center flex-col items-center h-screen')} >
      <div id={`${idElement}-items`} className='flex flex-1 justify-center flex-col items-center'>
        <img id={`${idElement}-image`} data-testid='error-image' className='w-40' src={image} alt='error-img' />
        <p id={`${idElement}-title`} data-testid='error-title' className='text-center text-2xl font-bold text-gray-primary leading-9 my-[12px]'>
          {title}
        </p>
        <p id={`${idElement}-detail`} data-testid='error-title' className='text-center text-sm text-gray-primary leading-5'>
          {detail}
        </p>
      </div>
      <div id={`${idElement}-button-content`} className='flex flex-0 w-full'>
        <button
          id={`${idElement}-button`}
          type='button'
          className={classNames(
            'bg-blue-iris text-lg text-white font-bold rounded-lg h-[44px]',
            'w-full py-[6px] px-[12px] mb-[10px] leading-7 mx-[24px]'
          )}
          onClick={() => onClick()}
        >
          ลองอีกครั้ง
        </button>
      </div>

    </div >
  )
}

export default GeneralError
