import { addGAEvent } from '../../utils/addGoogleAnalytic'
import classNames from 'classnames'
import React from 'react'

interface Props {
  onSelectMenu: Function
  active: number
}

const FilterOfferType: React.FC<Props> = ({ onSelectMenu, active }) => {
  const labels = ['สิทธิพิเศษโลตัส', 'สิทธิพิเศษร้านค้า']

  return (
    <div id="filter-offer-type" className="flex flex-col">
      <div id="filter-offer-type-content" className="flex flex-row mt-[4px] mx-[12px] border-1 rounded-lg border-gray-[#6B6B6B]">
        {labels.map((label, index) => {
          return (
            <div
              id={`filter-offer-type-label${index}`}
              key={index}
              className={classNames(
                'flex-1 text-center px-[8px] py-[6px] rounded-lg text-base font-bold leading-6 text-gray-empty',
                { 'bg-blue-iris text-white': active === index }
              )}
              onClick={() => {
                onSelectMenu(index)
                addGAEvent({
                  event: 'select_offer_source',
                  menu: label,
                })
              }}
            >
              {label}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FilterOfferType
