import { addGAEvent } from '../../../utils/addGoogleAnalytic'
import { OFFERS_TYPE } from '../../../utils/constant'
import { getParam } from '../../../utils/params'
import React from 'react'

const EmptyPremiumList: React.FC = () => {
  const token = getParam('token')
  const goToOffersPage = `/offers?type=${OFFERS_TYPE.PREMIUM}&token=${token}`

  function onClick() {
    addGAEvent({
      event: 'redeem_coupon_now'
    })
    if (window) {
      window.parent.postMessage('back-to-offers-list', '*')
    }
    window.history.replaceState('detail', '', goToOffersPage)
    window.location.reload()
  }

  return (
    <div data-testid="empty-page" className="flex justify-center items-center h-[calc(100vh-50px)]">
      <div className="flex flex-col">
        <img className="w-40" src="/images/empty-coupon.svg" alt="loading-icon" />
        <p className="font-medium text-base text-gray-empty">คุณไม่มีคูปองของพรีเมียม</p>
        <div className="flex justify-center my-6">
          <button
            type="button"
            className="bg-blue-iris text-base text-white font-bold rounded-lg py-1.5 px-2.5 hover:bg-red"
            onClick={onClick}
          >
            แลกคูปองเลย
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmptyPremiumList
