import { OfferDetails, Profile } from '../../services/my-coupon-bff/types'
import { getAvailableInventoryOffers, getOffers, getProfile } from '../../services/my-coupon-bff'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorResponse, HttpErrorResponse } from '../../utils/reponseType'
import { getParam } from '../../utils/params'
import { KEY_STORAGE, OFFERS_SPECIAL_LOTUS_TYPE, OFFERS_TYPE } from '../../utils/constant'
import { useParams } from 'react-router-dom'
import { FilterOfferLotusType, FilterOfferType, Footer, GeneralError } from '../../common'
import classNames from 'classnames'
import { OffersSpecialLotusList, OffersSpecialStore, OfferDetail } from '../../components'
import { checkNetworkError } from '../../utils/error'
import './OffersPage.css'
import { getLocalStorageString, removeLocalStorage, setLocalStorage } from '../../utils/storage'
import { Error as ErrorPremiumList } from '../../common'
import NetworkErrorLogo from '../../assets/images/network-error.svg'
import BadRequestImage from '../../assets/images/bad-request.svg'
import { addGAEvent } from '../../utils/addGoogleAnalytic'
import { getBZBToken, requestBZBToken } from '../../components/functions'

export interface ICounterOffer {
  premium: number
  voucher: number
  specialForYou: number
  all: number
}

// these constants must align with css of the elements
const maximumOffersFilterHeight = 94
let offersFilterHeight = 94
const offersMenuHeight = 40,
  startedOffersPagePaddingTop = offersFilterHeight + offersMenuHeight,
  footerSize = 52.5,
  startedOffersMenuTop = 0,
  startedOffersFiltersTop = offersMenuHeight

let offersMenuElement: HTMLElement | null = null
let offersPageFilterElement: HTMLElement | null = null
let dummyPaddingTop: HTMLElement | null = null
let offersListContainer: HTMLElement | null = null
let lastScrollTop = 0
let currentOffersFiltersTop = startedOffersFiltersTop
let currentOffersMenuTop = startedOffersMenuTop
let currentDummyPaddingTopHeight = startedOffersPagePaddingTop
let isDisabledScrollbar = false

const onScroll = (event: React.UIEvent<HTMLElement>) => {
  const { scrollTop } = event.currentTarget

  if (isDisabledScrollbar) return

  // dif of moving scrollbar
  const dif = lastScrollTop - scrollTop

  // calculate the new position of elements
  let updatedOffersFilterTop = currentOffersFiltersTop + dif
  let updatedOffersMenuTop = currentOffersMenuTop + dif
  let updatedDummyPaddingTopHeight = currentDummyPaddingTopHeight + dif

  // restrict the maximum and minimum of offers menu's top
  const minimumOffersMenuTop = -(offersMenuHeight + offersFilterHeight)
  if (updatedOffersMenuTop <= minimumOffersMenuTop) updatedOffersMenuTop = minimumOffersMenuTop
  else if (updatedOffersMenuTop >= 0) updatedOffersMenuTop = 0

  // restrict the maximum and minimum of offers filter's top
  const minimumOffersFilterTop = -offersFilterHeight,
    maximumOffersFilterTop = offersMenuHeight
  if (updatedOffersFilterTop <= minimumOffersFilterTop)
    updatedOffersFilterTop = minimumOffersFilterTop
  else if (updatedOffersFilterTop >= maximumOffersFilterTop)
    updatedOffersFilterTop = maximumOffersFilterTop

  // restrict the maximum and minimum of offers page's padding top
  const maximumOffersPagePaddingTop = offersMenuHeight + offersFilterHeight
  if (updatedDummyPaddingTopHeight <= 0) updatedDummyPaddingTopHeight = 0
  else if (updatedDummyPaddingTopHeight >= maximumOffersPagePaddingTop)
    updatedDummyPaddingTopHeight = maximumOffersPagePaddingTop

  // move elements
  offersPageFilterElement!.style.top = updatedOffersFilterTop.toString() + 'px'
  offersMenuElement!.style.top = updatedOffersMenuTop.toString() + 'px'
  if (dummyPaddingTop) {
    dummyPaddingTop!.style.height = updatedDummyPaddingTopHeight + 'px'
  }
  offersListContainer!.style.height = `calc(100% - ${updatedDummyPaddingTopHeight + footerSize}px)`

  // Update the last position of elements and scrollbar
  currentOffersMenuTop = updatedOffersMenuTop
  currentOffersFiltersTop = updatedOffersFilterTop
  currentDummyPaddingTopHeight = updatedDummyPaddingTopHeight
  lastScrollTop = scrollTop
}

const assignElementToVariables = () => {
  offersMenuElement = document.getElementById('offers-menu')!
  offersPageFilterElement = document.getElementById('offers-filter')!
  dummyPaddingTop = document.getElementById('dummy-padding-top')!
  offersListContainer = document.getElementById('offers-list-container')!

  const scrollableHeight = offersListContainer?.scrollHeight! - offersListContainer?.clientHeight!
  isDisabledScrollbar = scrollableHeight < offersMenuHeight + offersFilterHeight
}

const Offers: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [profile, setProfile] = useState<Profile>()
  const [isInternalError, setIsInternalError] = useState<boolean>(false)
  const [isLoadingAvailableInventory, setIsLoadingAvailableInventory] = useState<boolean>(true)
  const [selectedOfferType, setSelectedOfferType] = useState<string>(OFFERS_SPECIAL_LOTUS_TYPE.ALL)
  const [offers, setOffers] = useState<OfferDetails[]>()
  const [error, setError] = useState<ErrorResponse | null>()
  const [counterOfferByType, setCounterOfferByType] = useState<ICounterOffer>({
    premium: 0,
    voucher: 0,
    specialForYou: 0,
    all: 0,
  })
  const [selectedOfferTab, setSelectedOfferTab] = useState<number>(0)
  const [isRefreshOffers, setIsRefreshOffers] = useState<boolean>(false)
  const [isRefreshOffersSpecialStore, setIsRefreshOffersSpecialStore] = useState<boolean>(false)
  const [isRefreshCounterOfferType, setIsRefreshCounterOfferType] = useState<boolean>(false)
  const [lockScroll, setLockScroll] = useState<boolean>(false)
  const [isDisabledAnimation, setIsDisabledAnimation] = useState<boolean>(false)

  const { offerId } = useParams()
  const dataFetchedRef = useRef(false)
  const isNetworkError = checkNetworkError()
  const [isHideFilter, setHideFilter] = useState<boolean>(false)

  const [selectedOffer, setSelectedOffer] = useState<OfferDetails | null>(null)
  const [isShowingOfferDetail, setIsShowingOfferDetail] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  const [isShowSpecialStoreDetails, setIsShowSpecialStoreDetails] = useState<boolean>(false)

  // event message to line
  const [postMessageCounter, setPostMessageCounter] = useState<number>(0)
  const TOKEN_RECEIVED_MESSAGE = 'bzb-token-received'

  async function refreshProfileAndOffers() {
    setError(null)
    setIsInternalError(false)
    setLoading(true)
    if (selectedOfferTab === 0) {
      fetchProfile()
      setIsRefreshOffers(true)
      setIsRefreshCounterOfferType(true)
    } else {
      setIsRefreshOffersSpecialStore(true)
    }
  }

  const handleSelectedMenu = useCallback(
    (index: number) => {
      setSelectedOfferTab(index)
      setError(null)
      removeLocalStorage('special-store-id')
      if (index === 0) {
        setLoading(true)
        setIsRefreshOffers(true)
        setSelectedOfferType(OFFERS_SPECIAL_LOTUS_TYPE.ALL)
        localStorage?.setItem('tab', 'offer-list')

        window.parent.postMessage('selected-offer-list', '*')
        removeLocalStorage('search')
      } else {
        const bzbToken = getBZBToken()
        setIsRefreshOffersSpecialStore(true)
        localStorage?.setItem('tab', 'offer-special-list')
        if(!bzbToken) requestBZBToken(postMessageCounter, setPostMessageCounter)
        window.parent.postMessage('selected-offer-special-list', '*')
      }
    },
    [
      setSelectedOfferTab, 
      setLoading, 
      setIsRefreshOffers, 
      setError, 
      setSelectedOfferType,
      postMessageCounter,
    ]
  )

  const filterOffersByType = (type: string, offersData: OfferDetails[]) => {
    if (type && offersData) {
      return offersData.filter((offer) => offer.offerDetails.offerType === type)
    }
    return offersData
  }

  const onSelectOffer = (selectedOffer: OfferDetails) => {
    document.getElementById('scrollable-offer-detail')?.scrollTo({ top: 0 })
    setSelectedOffer(selectedOffer)
    setIsShowingOfferDetail(true)
    localStorage?.setItem('tab', 'offer-detail')
  }

  const counterOfferType = useCallback(
    (offerDetails: OfferDetails[]) => {
      const premiumType = []
      const voucherType = []
      const specialForYou = []

      if (offerDetails) {
        offerDetails.forEach((offer) => {
          if (offer) {
            if (offer.offerDetails.offerType === OFFERS_TYPE.PREMIUM) {
              premiumType.push(offer)

              return offer
            }
            if (offer.offerDetails.offerType === OFFERS_TYPE.VOUCHER) {
              voucherType.push(offer)

              return offer
            }
            if (offer.offerDetails.offerType === OFFERS_TYPE.SPECIAL_FOR_YOU) {
              specialForYou.push(offer)

              return offer
            }
          }
        })

        setCounterOfferByType({
          premium: premiumType.length,
          voucher: voucherType.length,
          specialForYou: specialForYou.length,
          all: offerDetails.length,
        })
      }
    },
    [setCounterOfferByType]
  )

  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getProfile()

      if (response) {
        const result = response as Profile

        setProfile(result)
      }
    } catch (e) {
      const error = e as ErrorResponse
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      if (error.code === 500) {
        setIsInternalError(true)
      }
      setLoading(false)
    }
  }, [])

  const fetchAvailableInventoryOffers = useCallback(async () => {
    setError(null)
    setIsLoadingAvailableInventory(true)

    try {
      const response = await getAvailableInventoryOffers()

      if (response) {
        let offerDetails = response as OfferDetails[] | []

        if (offerId) {
          const selectedOffer = offerDetails.find((offer) => offer.id === offerId) || null
          if (selectedOffer) {
            setSelectedOffer(selectedOffer)
          } else {
            setIsShowingOfferDetail(false)
          }
        }

        setOffers(offerDetails)
      }
      setIsLoadingAvailableInventory(false)
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      setError(e as ErrorResponse)
      setIsLoadingAvailableInventory(false)
    }
  }, [setOffers, setIsLoadingAvailableInventory, offerId])

  const fetchOffers = useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await getOffers()

      if (response) {
        let offerDetails = response as OfferDetails[]
        if (selectedOfferTab === 0 && isFirstLoad) {
          setIsFirstLoad(false)
          counterOfferType(offerDetails)
        }

        setOffers(offerDetails)
        setLoading(false)
      }
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      setError(e as ErrorResponse)
      setLoading(false)
    }
  }, [counterOfferType, selectedOfferTab, setLoading, selectedOfferType, isRefreshOffers])

  const getSubMenuList = () => {
    const subMenu = []

    if (counterOfferByType.all) {
      subMenu.push({ title: 'ทั้งหมด', icon: 'all-icon', type: OFFERS_SPECIAL_LOTUS_TYPE.ALL })
    }
    if (counterOfferByType.voucher) {
      subMenu.push({
        title: 'คูปองเงินสดโลตัส',
        icon: 'voucher-icon',
        type: OFFERS_SPECIAL_LOTUS_TYPE.VOUCHER,
      })
    }
    if (counterOfferByType.premium) {
      subMenu.push({
        title: 'แลกของพรีเมียม',
        icon: 'premium-icon',
        type: OFFERS_SPECIAL_LOTUS_TYPE.PREMIUM,
      })
    }
    if (counterOfferByType.specialForYou) {
      subMenu.push({
        title: 'พิเศษเฉพาะคุณ',
        icon: 'special-for-you-icon',
        type: OFFERS_SPECIAL_LOTUS_TYPE.SPECIAL_FOR_YOU,
      })
    }
    if (subMenu.length === 2 || subMenu.length === 0) {
      // to manual hide offer filter type section by keeping functionality of scrollbar behabior
      if (dummyPaddingTop) {
        const updatedDummyPaddingTopHeight =
          loading || subMenu.length === 0
            ? offersMenuHeight + maximumOffersFilterHeight
            : offersMenuHeight
        dummyPaddingTop!.style.height = updatedDummyPaddingTopHeight + 'px'
        offersFilterHeight = loading ? maximumOffersFilterHeight : 0
        currentDummyPaddingTopHeight = updatedDummyPaddingTopHeight
      }
      if (offersListContainer) {
        offersListContainer!.style.height = `calc(100% - ${
          currentDummyPaddingTopHeight + footerSize
        }px)`
      }
      return []
    }
    offersFilterHeight = 94
    return subMenu
  }

  const handleParentEvent = useCallback(
    async (event: MessageEvent) => {
      // Constants
      const POPSTATE = 'popstate'
      const PARENT_NAVIGATE_BACK = 'parent:navigate_back'
      const DEFAULT_TAB = 'offer-list'
      const specialStoreId = getLocalStorageString('special-store-id') || ''
      const action = getParam('action')
      const tab = getLocalStorageString('tab')
      const isNestedPage = getLocalStorageString('search') || false

      const handleNavigateBack = () => {
        if (isShowingOfferDetail && !action) {
          // Close the offer detail view
          setIsShowingOfferDetail(false)
          setTimeout(() => {
            setSelectedOffer(null)
          }, 300)
          localStorage?.setItem('tab', DEFAULT_TAB)
        } else if (tab === DEFAULT_TAB || tab === 'offer-special-list') {
          if (selectedOfferTab === 0 && tab === 'offer-special-list') {
            localStorage?.setItem('tab', DEFAULT_TAB)
            removeLocalStorage('special-store-id')
          }
          // Handle navigation for offer list or special offer list
          else if (specialStoreId) {
            removeLocalStorage('special-store-id')
            setIsRefreshOffersSpecialStore(true)
          } else if (isNestedPage && isNestedPage !== 'false') {
            window.parent.postMessage('search-offer-special', '*')
          } else {
            window.parent.postMessage(PARENT_NAVIGATE_BACK, '*')
          }
        }
        if (action) {
          if (window.parent) window.parent.postMessage('parent:navigate_back', '*')
        }
      }
      // request BZBToken frist time
      if (postMessageCounter === 0) requestBZBToken(postMessageCounter, setPostMessageCounter)

      if (typeof event.data === 'string') {
        if (event.data.match(TOKEN_RECEIVED_MESSAGE)) {
          const token = JSON.parse(event.data).token
          if (token) {
            localStorage.setItem('bzb_user_token', token)
          } else {
            requestBZBToken(postMessageCounter, setPostMessageCounter)
          }
        }
      }

      switch (event.data) {
        case 'iframe:navigate_back':
          if (tab === 'offer-special-detail' && selectedOfferTab === 1) {
            setIsRefreshOffersSpecialStore(true)
            removeLocalStorage('special-store-id')
            if (isNestedPage && isNestedPage !== 'false') {
              window.parent.postMessage('search-offer-special', '*')
            }
            if (!isNestedPage) {
              localStorage?.setItem('tab', DEFAULT_TAB)
            }
          }
          handleNavigateBack()
          break

        case KEY_STORAGE.COUPONS_EXCHANGED_LIST:
          setLocalStorage(KEY_STORAGE.COUPONS_EXCHANGED_LIST, true)
          break

        default:
          if (tab === DEFAULT_TAB || tab === 'offer-special-list') {
            if (tab === 'offer-special-list') removeLocalStorage('special-store-id')
          }
          break
      }
    },
    [isShowingOfferDetail, selectedOfferTab, requestBZBToken, postMessageCounter]
  )

  useEffect(() => {
    if (window) {
      const requestIdString = getParam('x-request-id')

      if (requestIdString) {
        const requestIdList = requestIdString.split(',')

        window.__IFRAME_DEBUGGER_PARAMS = requestIdList ?? []
      }
    }

    const type = getParam('type')

    // for offer special lotus detail
    if (offerId && type !== 'special-store') {
      setIsDisabledAnimation(true)
      setTimeout(() => {
        setIsDisabledAnimation(false)
      }, 50)
      setIsShowingOfferDetail(true)
    }
    
    // for offer special store
    if (type === 'special-store') {
      if (offerId) {
        setIsShowSpecialStoreDetails(true)
        setSelectedOfferTab(1)
        setIsRefreshOffersSpecialStore(true)
        setLocalStorage('special-store-id-from-param', true)
      } else {
        handleSelectedMenu(1)
      }
    }
  }, [])

  useEffect(() => {
    assignElementToVariables()
    if (!offersListContainer) return
    if (loading || getSubMenuList().length > 0) {
      offersListContainer.style.height = `calc(100% - 196px)`
    } else {
      offersListContainer!.style.height = `calc(100% - ${
        currentDummyPaddingTopHeight + footerSize
      }px)`
    }
    offersListContainer.scrollTo({ top: 0 })
    isDisabledScrollbar = loading
  }, [loading])

  useEffect(() => {
    if (dataFetchedRef.current) return

    if (!profile && !offers) {
      localStorage?.setItem('tab', 'offer-list')
      removeLocalStorage('special-store-id')
      removeLocalStorage('search')
      fetchProfile()
      fetchOffers()
      fetchAvailableInventoryOffers()
    }

    dataFetchedRef.current = true
  }, [fetchProfile, fetchOffers, fetchAvailableInventoryOffers, offers, profile])

  useEffect(() => {
    if (isRefreshOffers) {
      setIsRefreshOffers(false)

      fetchOffers()
      fetchAvailableInventoryOffers()
    }
    if (isRefreshCounterOfferType && offers) {
      if (isFirstLoad) {
        counterOfferType(offers)
      }
      setIsRefreshCounterOfferType(false)
    }
  }, [
    offers,
    isRefreshCounterOfferType,
    isRefreshOffers,
    fetchOffers,
    fetchAvailableInventoryOffers,
    counterOfferType,
  ])

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleParentEvent)
    }
    return () => {
      window.removeEventListener('message', handleParentEvent)
    }
  }, [isShowingOfferDetail, handleParentEvent])

  useEffect(() => {
    assignElementToVariables()
  }, [])

  return (
    <div
      data-testid="offers-page"
      id="offers-page"
      className={classNames('offers', 'h-[100svh] overflow-y-hidden')}
    >
      <div id="offers-network-error" className={classNames({ hidden: !isNetworkError })}>
        <GeneralError
          idElement="offers-network-general-error"
          title={'เครือข่ายขัดข้อง'}
          detail={'กรุณาตรวจสอบการเชื่อมต่อและลองใหม่อีกครั้ง'}
          image={NetworkErrorLogo}
          onClick={() => refreshProfileAndOffers()}
        />
      </div>
      <div id="offers-internal-error" className={classNames({ hidden: !isInternalError })}>
        <GeneralError
          idElement="offers-internal-general-error"
          title={'ขออภัย มีบางอย่างผิดพลาด'}
          detail={'กรุณาลองอีกครั้ง'}
          image={BadRequestImage}
          onClick={() => refreshProfileAndOffers()}
        />
      </div>
      <div
        data-testid="offers-menu"
        id="offers-menu"
        className={classNames('fixed top-0 w-full h-[40px]', {
          hidden: isNetworkError || isInternalError || isHideFilter,
        })}
      >
        <FilterOfferType
          onSelectMenu={(index: number) => handleSelectedMenu(index)}
          active={selectedOfferTab}
        />
      </div>
      <div
        data-testid="offers-filter"
        id="offers-filter"
        className={classNames('w-full', {
          hidden:
            error ||
            isNetworkError ||
            isInternalError ||
            selectedOfferTab !== 0 ||
            (!loading && getSubMenuList().length === 0),
          'fixed top-[40px] h-[94px]': selectedOfferTab !== 1,
        })}
      >
        <FilterOfferLotusType
          list={getSubMenuList()}
          active={selectedOfferType}
          onClick={(type: string) => {
            setSelectedOfferType(type)
            setIsRefreshOffers(true)
            const menuType =
              type === OFFERS_SPECIAL_LOTUS_TYPE.PREMIUM
                ? 'แลกของพรีเมียม'
                : type === OFFERS_SPECIAL_LOTUS_TYPE.VOUCHER
                ? 'คูปองส่วนลดเงินสด'
                : type === OFFERS_SPECIAL_LOTUS_TYPE.SPECIAL_FOR_YOU
                ? 'พิเศษเฉพาะคุณ'
                : 'ทั้งหมด'
            addGAEvent({
              event: 'offer_category_icon',
              menu: menuType,
            })
          }}
          loading={loading}
        />
      </div>
      {selectedOfferTab === 0 && (loading || offers?.length !== 0) ? (
        <div id="dummy-padding-top" className="dummy-padding-top"></div>
      ) : (
        ''
      )}
      <div
        data-testid="offers-list"
        id="offers-list-container"
        className={classNames('offers-list-container overscroll-y-none overflow-y-auto', {
          hidden: selectedOfferTab !== 0 || error,
          'hide-scroll': offers?.length === 0,
        })}
        onScroll={offers && offers.length > 0 ? onScroll : () => {}}
      >
        <OffersSpecialLotusList
          loadingAvailable={isLoadingAvailableInventory}
          offers={filterOffersByType(selectedOfferType, offers!) || null}
          loading={loading}
          setLoading={setLoading}
          error={error || null}
          name={profile?.name || 'N/A'}
          point={profile?.points}
          setLockScroll={setLockScroll}
          selectedType={selectedOfferType}
          isHideSubMenu={selectedOfferTab !== 0}
          onRefresh={() => setIsRefreshOffers(true)}
          onSelectOffer={onSelectOffer}
        />
      </div>
      <div
        id="offers-store-list-container"
        data-testid="offers-special-list"
        className={classNames({ hidden: selectedOfferTab !== 1 || isNetworkError })}
      >
        <OffersSpecialStore
          isRefresh={isRefreshOffersSpecialStore}
          onRefresh={setIsRefreshOffersSpecialStore}
          setHideFilter={setHideFilter}
          isHideFilter={isHideFilter}
          isShowSpecialStoreDetails={isShowSpecialStoreDetails}
        />
      </div>
      <div
        id="offers-error-premium-list-container"
        className={classNames('error-page-container', {
          hidden: !error || selectedOfferTab === 1,
        })}
      >
        <ErrorPremiumList
          idElement="offers-error-premium-list"
          isFooter={true}
          onClick={() => setIsRefreshOffers(true)}
          status={error?.code || 500}
          refCode={error?.refCode || ''}
        />
      </div>
      <div
        id="offer-details-container"
        className={classNames('offer-details', {
          show: isShowingOfferDetail,
          hide: !isShowingOfferDetail,
          'disabled-animation': isDisabledAnimation,
        })}
      >
        <OfferDetail
          loading={loading && isLoadingAvailableInventory}
          name='Lotus’s'
          offer={selectedOffer}
          setLoading={setLoading}
          profile={profile}
        />
      </div>
      <div
        id="offers-footer"
        data-testid="offers-footer"
        className={classNames('fixed inset-x-0 bottom-0 z-[5]', {
          hidden: isNetworkError || isInternalError || selectedOfferTab === 1,
        })}
        onClick={() =>
          addGAEvent({
            event: 'mylotuss_card',
          })
        }
      >
        <Footer
          loading={loading}
          displayName={profile?.name || 'N/A'}
          cardName="มายโลตัส"
          coin={profile?.points || 0}
        />
      </div>
    </div>
  )
}

export default Offers
