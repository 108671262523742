import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardDetailLoading } from '../../../common'
import { OfferDetails } from '../../../services/my-coupon-bff/types'
import { DATE_FORMAT, getDate } from '../../../utils/date'
import { getStatus } from '../../../utils/status'
import { ErrorResponse } from '../../../utils/reponseType'
import Loading from '../../premium/Loading'
import classNames from 'classnames'
import EmptyOffersSpecialLotus from './EmptyOffersSpecialLotus'
import { LOTUS } from '../../../utils/constant'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'

export interface Props {
  loading: boolean
  setLoading: (loading: boolean) => void
  name: string
  point: number | undefined
  offerId?: string
  setLockScroll: (lockScroll: boolean) => void
  selectedType: string
  isHideSubMenu: boolean
  onRefresh: Function
  offers: OfferDetails[] | null
  loadingAvailable: boolean
  error: ErrorResponse | null
  onSelectOffer: (offerDetails: OfferDetails) => void
}

const OffersSpecialLotusList: React.FC<Props> = ({
  loading,
  point,
  offerId,
  setLockScroll,
  error,
  selectedType,
  offers,
  loadingAvailable,
  onSelectOffer
}) => {

  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage('iframe:ready', '*')
    }
  }, [])

  const handleOpenOfferDetail = (index: number) => {
    setLockScroll(true)
    onSelectOffer(offers![index])
    const internalName = offers![index].offerDetails.internalName
    addGAEvent({
      event: 'select_offer_item',
      offer_name: internalName,
    })
  }

  if (loading) {
    return <>
      {offerId ? <CardDetailLoading idElement="offers-special-lotus-card-loading" /> : <div id="offers-special-lotus-loading-container" data-testid='loading-offers-special-lotus'><Loading idElement="offers-special-lotus-loading" /></div>}
    </>
  }

  if (error) {
    if (error?.code === 401) {
      return <>{offerId ? <CardDetailLoading idElement="offers-special-lotus-card-loading" /> : <Loading idElement="offers-special-lotus-loading" />}</>
    } else {
      return <Fragment />
    }
  }

  if (!offers?.length && !loading) {
    const isEmptyOfferFromSubType = !!selectedType && offers?.length === 0

    return (
      <EmptyOffersSpecialLotus
        isHasHeader={isEmptyOfferFromSubType}
        message={
          isEmptyOfferFromSubType ? 'ติดตามสิทธิพิเศษได้เร็วๆนี้' : 'ติดตามสิทธิพิเศษได้เร็วๆนี้'
        }
      />
    )
  }

  return (
    <div
      id="offers-special-lotus-list"
      data-testid='offers-special-lotus-list-component'
      className={classNames(
        `flex flex-col`,
        {
          'hidden': !offers?.length
        },
      )}
    >
      {offers?.length &&
        offers.map(({ id, price, offerDetails, redemptionEndDate }, index) => {
          const date = getDate(redemptionEndDate!, DATE_FORMAT)
          let status = null

          if (!loadingAvailable) {
            const availableInventoryRemaining = offers[index]?.availableInventoryRemaining
            status = getStatus(redemptionEndDate!, point, price, availableInventoryRemaining)
          }

        return (
          <Fragment key={id}>
            <div
              id={`offers-special-lotus-list-content${index}`}
              data-testid={`card-component-${id}`}
              onClick={() => handleOpenOfferDetail(index)}
              className={classNames(
                {
                  'pb-[16px]': index === offers.length - 1
                }
              )}
            >
              <Card
                idElement={`offers-special-lotus-list-card${index}`}
                key={id}
                company={LOTUS}
                title={offerDetails.title}
                fontTitle='text-[16px] leading-6'
                date={date}
                status={status}
                image={offerDetails.media.url}
                loading={loadingAvailable && point !== null}
              />
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default OffersSpecialLotusList
