import { CardLoading } from '../../common'
import React from 'react'

interface Props {
  idElement: string
}

const Loading: React.FC<Props> = ({ idElement }) => {
  return (
    <div id={idElement} data-testid='loading-page'>
      <CardLoading idElement={`${idElement}-card-loading0`} />
      <CardLoading idElement={`${idElement}-card-loading1`} />
      <CardLoading idElement={`${idElement}-card-loading2`} />
      <CardLoading idElement={`${idElement}-card-loading3`} />
      <CardLoading idElement={`${idElement}-card-loading4`} />
      <CardLoading idElement={`${idElement}-card-loading5`} />
    </div>
  )
}

export default Loading
