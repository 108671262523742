import classNames from 'classnames'
import React from 'react'

export interface Props {
  idElement: string
  isShowModal: boolean
  children?: string | JSX.Element | JSX.Element[]
}

const Modal: React.FC<Props> = ({ idElement, isShowModal, children }) => {
  return (
    <div
      id={idElement}
      className={classNames('fixed z-[1500]', {
        'hidden': !isShowModal
      })}
    >
      <div id={`${idElement}-bg`} className='fixed inset-0 bg-black opacity-70'></div>
      <div id={`${idElement}-container`} className={classNames(
        'duration-300 rounded-t-3xl bg-white fixed inset-x-0 bottom-[0] z-[30]',
        {
          'animate-modalUp translate-y-0': isShowModal,
        }
      )}>
        {children}
      </div>
    </div>
  )
}

export default Modal