import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

interface TimerDisplayProps {
  initialTime: number
  setTimeout: Function
  timeout: boolean
}

const Timer: React.FC<{ time: number, title: string, isExpired: boolean }> = ({ time, title, isExpired }) => {
  const formatTime = (t: number) => {
    return String(t).padStart(2, '0')
  }

  return (
    <div>
      <div className='flex flex-row'>
        <p className={classNames(
          'px-[8px] pt-[4px] pb-[8px] leading-8 mx-[2px] bg-white rounded-lg font-bold',
          { 
            'text-[#7D7D7D]': isExpired,
            'text-gray-primary': !isExpired,
          }
        )}>
          {formatTime(time)[0]}
        </p>
        <p className={classNames(
          'px-[8px] pt-[4px] pb-[8px] leading-8 mx-[2px] bg-white rounded-lg font-bold',
          { 
            'text-[#7D7D7D]': isExpired,
            'text-gray-primary': !isExpired,
          }
        )}>
          {formatTime(time)[1]}
        </p>
      </div>
      <p className='mt-[4px] text-sm text-gray-secondary font-medium text-center'>
        {title}
      </p>
    </div>
  )
}

const TimerDisplay: React.FC<TimerDisplayProps> = ({
  initialTime,
  setTimeout,
  timeout,
}) => {
  const startTime = Math.abs(initialTime)
  const [timeLeft, setTimeLeft] = useState({
    hours: Math.floor(startTime / 3600) || 0,
    minutes: Math.floor((startTime % 3600) / 60) || 0,
    seconds: startTime % 60,
  })

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (
          prevTime.hours === 0 &&
          prevTime.minutes === 0 &&
          prevTime.seconds === 0
        ) {
          setTimeout(true)
          clearInterval(countdown)

          return prevTime
        }

        const newTime = { ...prevTime }

        if (newTime.seconds > 0) {
          newTime.seconds -= 1
        } else {
          newTime.seconds = 59
          if (newTime.minutes > 0) {
            newTime.minutes -= 1
          } else {
            newTime.minutes = 59
            if (newTime.hours > 0) {
              newTime.hours -= 1
            }
          }
        }

        return newTime
      })
    }, 1000)

    return () => clearInterval(countdown)
  }, [setTimeout])

  return (
    <div className='flex flex-col'>
      {(timeout) && (
        <div className='flex flex-col justify-center items-center rounded-lg bg-white-smoke py-[8px]'>
          <img
            src={'/icons/time-icon.png'}
            className='rounded-lg w-[48px]'
            alt='time-icon'
          />
          <span className='text-gray-secondary text-sm'>ขออภัยคุณไม่สามารถใช้คูปองนี้ได้</span>
          <span className='text-gray-secondary text-sm'>เนื่องจากคูปองหมดอายุ</span>
        </div>
      )}

      <div className={classNames(
        'flex flex-row justify-center text-4xl font-bold font-lotuss rounded-lg bg-white-smoke pt-[16px] pb-[8px] mt-[12px]'
      )}>
        <Timer time={timeLeft.hours} title={'Hour'} isExpired={timeout} />
        <p className={classNames(
          'm-[2px] leading-8 text-4xl text-center',
          { 
            'text-[#7D7D7D]': timeout,
            'text-gray-primary': !timeout,
          }
        )}>:</p>
        <Timer time={timeLeft.minutes} title={'Min'} isExpired={timeout} />
        <p className={classNames(
          'm-[2px] leading-8 text-4xl text-center',
          { 
            'text-[#7D7D7D]': timeout,
            'text-gray-primary': !timeout,
          }
        )}>:</p>
        <Timer time={timeLeft.seconds} title={'Sec'} isExpired={timeout} />
      </div>
    </div>
  )
}

export default TimerDisplay
