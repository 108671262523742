import QRCode from 'qrcode'
import { useEffect, useRef } from 'react'

export interface ReactQRCodeProps {
  code: string
  options?: QRCode.QRCodeRenderersOptions
}

const ReactQRCode = ({ code, options }: ReactQRCodeProps) => {
  const containerRef = useRef<any>(null)

  useEffect(() => {
    containerRef.current.innerHTML = ''
    QRCode.toCanvas(containerRef.current, code, options)
  }, [containerRef, code, options])

  return (<canvas ref={containerRef}></canvas>)
}

export default ReactQRCode