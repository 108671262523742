import { CouponLoading } from '../../../common'
import React from 'react'

const Loading: React.FC = () => {
  return (
    <div data-testid="loading-page" id="loading-page" className="overflow-x-hidden">
      <div className="animate-pulse flex flex-row mb-[12px] ml-[12px] w-[120px] h-[20px] z-[10]">
        <p className="bg-gradient-to-l from-smoke to-white-smoke rounded-[15px] w-[120px] h-[20px]`}" />
      </div>
      <CouponLoading />
      <CouponLoading />
      <CouponLoading />
      <CouponLoading />
      <CouponLoading />
      <CouponLoading />
    </div>
  )
}

export default Loading
