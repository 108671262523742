import React from 'react'

const EmptySearch: React.FC = () => {
  return (
    <div
      data-testid="empty-offers-special-store-page"
      id="empty-offers-special-store-page"
      className="m-auto"
    >
      <div className="flex flex-col mb-[48px]">
        <img className="w-40" src="/icons/empty-search.svg" alt="loading-icon" />
        <p className="mt-[24px] font-medium text-base text-gray-empty text-center">
          ไม่พบคูปองที่เกี่ยวข้อง
        </p>
        <p className="font-medium text-base text-gray-empty text-center">กรุณาลองคำค้นหาอื่น</p>
      </div>
    </div>
  )
}

export default EmptySearch
