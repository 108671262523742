import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'

dayjs.locale('th')
dayjs.extend(buddhistEra)

export enum PREMIUM_STATUS {
  EMPTY = 'EMPTY',
  NOT_ENOUGH = 'NOT_ENOUGH',
  EXPIRED = 'EXPIRED',
  VALID = ''
}

export const isExpired = (date: string) => {
  const dateNow = dayjs(new Date())
  if (dayjs(date).isBefore(dateNow)) {
    return PREMIUM_STATUS.EXPIRED
  }
}

export const getStatus = (date: string, point: number | undefined, price: number | null, available?: number | null) => {
  let status = PREMIUM_STATUS.VALID

  if (isExpired(date)) {
    status = PREMIUM_STATUS.EXPIRED
  }
  if ((point || point === 0) && (price && point < price)) {
    status = PREMIUM_STATUS.NOT_ENOUGH
  }
  if (!available && available === 0) {
    status = PREMIUM_STATUS.EMPTY
  }
  if (point === undefined) {
    status = PREMIUM_STATUS.VALID
  }

  return status
}

export enum OFFERS_SPECIAL_STORE_STATUS {
  EMPTY = 'EMPTY',
  NOT_ENOUGH = 'NOT_ENOUGH',
  REMAINDER = 'REMAINDER',
  VALID = ''
}

export const getStatusOffersSpecialStore = (point?: number, pointPerUnit?: number, qty?: number) => {
  let status = OFFERS_SPECIAL_STORE_STATUS.VALID

  if ((point || point === 0) && ((pointPerUnit || pointPerUnit === 0) && point < pointPerUnit)) {
    status = OFFERS_SPECIAL_STORE_STATUS.NOT_ENOUGH
  }
  if (qty && qty <= 200) {
    status = OFFERS_SPECIAL_STORE_STATUS.REMAINDER
  }
  if (!qty && qty === 0) {
    status = OFFERS_SPECIAL_STORE_STATUS.EMPTY
  }

  return status
}