import classNames from 'classnames'
import * as React from 'react'

interface Props {
  idElement: string
  isShow: boolean
}

const Loading: React.FC<Props> = ({ idElement, isShow }) => {
  return (
    <div id={idElement} className={classNames('relative flex justify-center content-center z-[150] top-[30%]', { 'hidden': !isShow })} >
      <div id={`${idElement}-spinner`}  className='absolute rounded-2xl flex justify-center content-center items-center h-[80px] w-[80px] bg-black opacity-70'>
        <img id={`${idElement}-spinner-icon`} src='/icons/spinner.svg' alt='loading-icon' className='absolute  w-[13] animate-spin' />
      </div>
    </div>
  )
}

export default Loading